import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-bold text-gray-50 dark:text-gray-700 uppercase mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: _ctx.customFlex + ' ' + _ctx.padding
  }, [
    (_ctx.headerText.length > 0)
      ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.headerText), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}