import {Boostpack} from "@/services/boostpack/boostpack.type";
import {PaginationMetaInterface} from "@/services/boostpack/core/pagination_meta.interface";

export class BoostpackList extends Array<Boostpack> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Boostpack> = []) {
		super();
		this.push(...items);
		Object.setPrototypeOf(this, BoostpackList.prototype);
	}

	add(items: Boostpack) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Boostpack {
		return this[index];
	}

	getItemById(boostpackId: number): Boostpack | undefined {
		return this.find( ({ id }) => id === boostpackId);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}