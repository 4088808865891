
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router'
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: 'FooterItem',
	components: {DefaultIcon},
	props: {
		itemText: {
			type: String,
			default: ""
		},
		itemIconPre: {
			type: String,
			default: ""
		},
		itemIconAfter: {
			type: String,
			default: ""
		},
		itemWidth: {
			type: String,
			default: "",
		},
		link: {
			type: String,
			default: "",
		},
		routeName: {
			type: String,
			default: "",
		},
		showItem: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		}
	},
	setup(props) {
		const route = useRoute();
		const redirectLink = ref(props.link);
		const activeRoute = ref();

		function isRouteActive(): boolean {
			if (props.routeName.length > 0) {
				return route.name === props.routeName;
			} else {
				return route.path === props.link;
			}
		}

		onMounted(() => {
			activeRoute.value = isRouteActive();
		})

		//eslint-disable-next-line
		watch(route, (route, prevRoute) => {
			activeRoute.value = isRouteActive();
		})

		return {
			redirectLink, activeRoute
		};
	}
})
