import {computed} from 'vue';
import {store} from "@/store";
import {ProfileType, UserType} from "@/services/api/types/user.interface";

export default function useUserRepository() {
	const getToken = computed(() => store.getters['auth/getToken']);
	const getUserToken = computed(() => store.getters['user/getToken']);
	const getFullName = computed(() => store.getters['user/fullName']);
	const getUserImage = computed(() => store.getters['user/getProfileImage']);
	const getProfile = computed(() => store.getters['user/getProfile']);
	const getUserEmail = computed(() => store.getters['user/getEmail']);
	const getUserInitials = computed(() => store.getters['user/getUserInitials']);
	const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

	function setName(name: string) {
		store.commit('user/name', name)
	}

	function setEmail(email: string) {
		store.commit('user/email', email)
	}

	function setProfileImage(profileImage: string) {
		store.commit('user/profileImage', profileImage)
	}

	function setProfile(profile?: ProfileType) {
		store.commit('user/profile', profile)
	}

	function setAuthenticated(authenticated: boolean) {
		store.commit('auth/authenticated', authenticated);
	}

	function setToken(token: string) {
		store.commit('auth/token', token);
	}

	function setUserToken(token: string) {
		store.commit('user/token', token);
	}

	function initUserByUserType(userResponse: UserType) {
		setEmail(userResponse.email);
		setName(userResponse.name ?? "");
		setProfileImage(userResponse.avatarUrl ?? "");
		setProfile(userResponse.profile);
		setAuthenticated(true);
	}

	return {
		getFullName,
		getUserImage,
		getUserEmail,
		getToken,
		getUserToken,
		getProfile,
		getUserInitials,
		isAuthenticated,
		setName,
		setEmail,
		setProfileImage,
		setAuthenticated,
		setToken,
		setUserToken,
		initUserByUserType,
	}
}
