
import {defineComponent, ref} from 'vue';

export default defineComponent({
	name: 'HeaderLogo',
	components: {},
	props: {
		logoPath: {
			type: String,
			default: "/img/icons/logo/logo_white.png",
		},
		logoPathdark: {
			type: String,
			default: "/img/icons/logo/logo_dark.png",
		},
		logoHeight: {
			type: String,
			default: "h-1/4",
		},
		logoWidth: {
			type: String,
			default: "",
		},
		logoPadding: {
			type: String,
			default: "px-3 py-2",
		},
		logoFontSize: {
			type: String,
			default: 'text-lg',
		},
		link: {
			type: String,
			default: "/",
		},
		cssClass: {
			type: String,
			default: "",
		}
	},
	setup(props) {
		const redirectLink = ref(props.link);
		const logoText = ref(props.logoPath.substring(props.logoPath.lastIndexOf('/')+1));

		return {
			redirectLink, logoText
		};
	}
})
