import {computed} from 'vue';
import {store} from "@/store";
import {BoostpackList} from "@/services/boostpack/boostpack_list.type";
import {Boostpack} from "@/services/boostpack/boostpack.type";

export default function useBoostpackItemRepositories() {
	const getForceReload = computed(() => store.getters['boostpack/getReloadData']);
	const getBoostpacks = computed(() => store.getters['boostpack/getBoostpacks']);

	function getBoostpackById(boostpackId: number): Boostpack {
		return store.getters['boostpack/getBoostpackById'](boostpackId);
	}

	function getBoostpackByReference(boostpackReference?: string): Boostpack {
		return store.getters['boostpack/getBoostpackByReference'](boostpackReference);
	}

	function setBoostpacks(boostpackList: BoostpackList) {
		store.commit('boostpack/boostpackList', boostpackList)
	}

	function addBoostpacks(boostpackList: BoostpackList, clearBoostpackList = false) {
		store.commit('boostpack/addBoostpackList', {boostpackList, clearBoostpackList})
	}

	function addBoostpack(boostpack: Boostpack, forceReload = false) {
		if (forceReload) {
			store.commit('boostpack/removeBoostpackFromList', boostpack);
		}
		store.commit('boostpack/addBoostpackToList', boostpack);
	}

	function removeBoostpack(boostpackId: number) {
		store.commit('boostpack/removeBoostpackFromList', boostpackId);
	}

	function initBoostpacks(boostpacks: any, clearBoostpackList = false) {
		addBoostpacks(boostpacks, clearBoostpackList);
	}

	function setForceReload(reloadData = true) {
		store.commit('boostpack/reloadData', reloadData);
	}

	return {
		getBoostpacks,
		getBoostpackById,
		getBoostpackByReference,
		getForceReload,
		setBoostpacks,
		setForceReload,
		addBoostpacks,
		addBoostpack,
		initBoostpacks,
		removeBoostpack,
	}
}
