import router from "@/router";
import useUserRepository from "@/composable/user/useUserRepository";
import {AuthService} from "@/services/api/auth/auth.service";
const {isAuthenticated} = useUserRepository();

export default function authMiddleware({next, to, from, store}) {
	const toPath = to.path;
	const env = process.env.NODE_ENV;

	if (env === "development") {
		if (window.location.host.includes("localhost")) {
			return next();
		}
	}

	if ( (isAuthenticated.value) && (toPath === '/login' ||  toPath === '/register' || toPath === '/forgotPassword') ) {
		router.push('/');
	} else if ((!isAuthenticated.value) && (toPath !== '/profile')  ) {
		AuthService.signOut();
	}

	return next();
}
