

import DefaultRedirectButton from "@/components/Button/DefaultRedirectButton.vue";
import DefaultSwiper from "@/components/Swiper/DefaultSwiper.vue";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import Search from "@/components/Search/Search.vue";
import useCustomStore from "@/composable/custom/useCustomStore";
import useBoostpackItemHelper from "@/composable/boostpack/useBoostpackItemHelper";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import {onMounted, ref} from 'vue'
import router from "@/router";

export default {
	components: {
		Search, DefaultCardLayout, DefaultRedirectButton
	},
	props: {},

	setup() {
		const {setPageReloaded} = useCustomStore();
		const {getAltImageTextByTitleDescriptionAndCategory} = useBoostpackItemHelper();
		const boostpackItems = ref<string[]>([]);
		const boostpackFeedLoading = ref(false);

		async function loadBoostpackItems() {
			const bpListTmp = await BoostpackService.getBoostpackItemFeedWithAdditionalInfo("10");
			if (bpListTmp) {
				boostpackItems.value = bpListTmp;
			}

			boostpackFeedLoading.value = false;
		}

		//TODO check detail images count with api and update or delete images
		onMounted(() => {
			// if (pageReloaded.value || !boostpackItems.value) {
			// 	boostpackFeedLoading.value = true;
			// }
			// const boostpackSearchValue = useRoute().query.search;
			// if (boostpackSearchValue) {
			// 	// boostpackSearchValue = String(boostpackSearchValue);
			// 	// store.commit('boostpack/search', boostpackSearchValue);
			// 	loadBoostpackItems();
			// } else {
			// 	loadBoostpackItems();
			// 	if (pageReloaded.value) {
			// 		// getBoostpackItems();
			// 		setPageReloaded(false);
			// 		// } else if (!BoostpackItemList.value || !BoostpackItemList.value.length) {
			// 		// 	getBoostpackItems();
			// 	}
			// }
			// boostpackFeedLoading.value = false;
		})

		/**
		 *
		 */
		function isBoostpackFeedLoading(): boolean {
			return boostpackFeedLoading.value === true;
		}

		function redirectFeed() {
			router.push('/feed/');
		}

		window.addEventListener('beforeunload', function (event) {
			console.log(event);
			setPageReloaded(true);
		})

		return {
			boostpackItems,
			boostpackFeedLoading,
			isBoostpackFeedLoading,
			getAltImageTextByTitleDescriptionAndCategory,
			redirectFeed
		}
	},
}
