import ApiService from "../base/api.service";
import {TokenService} from "./token.service";
import {AxiosRequestConfig} from "axios";
import {AddressType, ProfileType, UserType} from "@/services/api/types/user.interface";
import useUserRepository from "@/composable/user/useUserRepository";
import router from "@/router";

class AuthenticationError extends Error {
	//eslint-disable-next-line
	errorCode: any;

	//eslint-disable-next-line
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
		console.log(errorCode, message);
	}
}

const AuthService = {

	calculateTokenLifeTime() {
		const date = new Date();
		date.setDate(date.getDate() + 10);
		return date;
	},

	/* eslint-disable @typescript-eslint/camelcase */
	//eslint-disable-next-line
	signIn: async function (signInData: any) {
		try {
			const response = await ApiService.post("/login", signInData);
			const token = response.data.data.token;
			const tokenLifeTime = response.data.data.tokenLifeTime ?? this.calculateTokenLifeTime();
			if (token) {
				TokenService.saveToken(token);
				TokenService.saveRefreshToken(token);
				TokenService.setTokenLifeTime(tokenLifeTime);
				ApiService.setHeader();
			}
			ApiService.mount401Interceptor();
			return token;
		} catch (error) {
			this.catchError(error);
		}
	},

	/* eslint-disable @typescript-eslint/camelcase */
	//eslint-disable-next-line
	signInSocialite: async function (UserToken: any, UserTokenLifeTime?: any) {
		try {
			const token = UserToken;
			let tokenLifeTime = UserTokenLifeTime;
			if (!tokenLifeTime) {
				tokenLifeTime = this.calculateTokenLifeTime();
			}
			if (token) {
				TokenService.saveToken(token);
				TokenService.saveRefreshToken(token);
				TokenService.setTokenLifeTime(tokenLifeTime);
				ApiService.setHeader();
				// await this.initUser();
			}
			ApiService.mount401Interceptor();
			return token;
		} catch (error) {
			this.catchError(error);
		}
	},

	getUser: async function (): Promise<UserType | undefined> {
		try {
			const response = await ApiService.get("/user");
			if (response) {
				const userResponse = {} as UserType;
				const userProfile = {} as ProfileType;
				const responseData = response.data.data;
				if (responseData.profile) {
					const responseProfileData = responseData.profile;
					userProfile.id = responseProfileData.id;
					userProfile.user_id = responseProfileData.user_id;
					userProfile.country_code = responseProfileData.country_code;
					userProfile.language = responseProfileData.language;
					userProfile.first_name = responseProfileData.first_name;
					userProfile.last_name = responseProfileData.last_name;
					userProfile.username = responseProfileData.username;
					userProfile.degree = responseProfileData.degree;
					userProfile.gender = responseProfileData.gender;
					userProfile.birthday = responseProfileData.birthday;
					userProfile.job = responseProfileData.job;
					userProfile.profile_picture = responseProfileData.profile_picture;
					userProfile.hide_profile = responseProfileData.hide_profile;
					userProfile.hide_name = responseProfileData.hide_name;
					userProfile.beta = responseProfileData.beta;
					userProfile.updated_at = responseProfileData.updated_at;

					const userAddress = {} as AddressType;
					if (responseProfileData.address) {
						//TODO
					}
					userProfile.address = userAddress;
				}
				userResponse.id = responseData.id;
				userResponse.email = responseData.email;
				userResponse.name = responseData.name;
				userResponse.email_verified_at = responseData.email_verified_at;
				if (responseData.avatarUrl) {
					userResponse.avatarUrl = responseData.avatarUrl;
				} else if (userProfile.profile_picture) {
					userResponse.avatarUrl = userProfile.profile_picture;
				} else {
					userResponse.avatarUrl = undefined;
				}
				userResponse.profile = userProfile;
				userResponse.state = responseData.state;
				userResponse.type = responseData.type;
				userResponse.updated_at = responseData.updated_at;
				return userResponse;
			}
		} catch (error) {
			this.catchError(error);
		}
	},

	refreshToken: async function () {
		try {
			const response = await ApiService.get("/get");

			TokenService.saveToken(response.data.access_token);
			TokenService.saveRefreshToken(response.data.refresh_token);
			ApiService.setHeader();

			return response.data.access_token;
		} catch (error) {
			throw new AuthenticationError(
				error.response.status,
				error.response.data.error_description
			);
		}
	},

	// refreshToken: async function() {
	// 	const refreshToken = TokenService.getRefreshToken();
	//
	// 	const requestData: AxiosRequestConfig = {
	// 		method: "post",
	// 		headers: {
	// 			"Content-Type": "application/x-www-form-urlencoded",
	// 			// Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET) //Todo use bearer token
	// 		},
	// 		url: "/oauth/token",
	// 		data: qs.stringify({
	// 			"grant_type": "refresh_token",
	// 			refreshToken: refreshToken
	// 		})
	// 	};
	//
	// 	try {
	// 		const response = await ApiService.customRequest(requestData);
	//
	// 		TokenService.saveToken(response.data.access_token);
	// 		TokenService.saveRefreshToken(response.data.refresh_token);
	// 		ApiService.setHeader();
	//
	// 		return response.data.access_token;
	// 	} catch (error) {
	// 		throw new AuthenticationError(
	// 			error.response.status,
	// 			error.response.data.error_description
	// 		);
	// 	}
	// },

	async signOut(redirect = true) {
		try {
			await ApiService.post("/logout", null);
			TokenService.removeToken();
			TokenService.removeRefreshToken();
			ApiService.removeHeader();
			ApiService.unmount401Interceptor();
			window.localStorage.clear();
			// store.reset();
			if (redirect) {
				router.push('/login');
			}
			this.resetUserData();
			return true;
		} catch (error) {
			TokenService.removeToken();
			TokenService.removeRefreshToken();
			ApiService.removeHeader();
			ApiService.unmount401Interceptor();
			window.localStorage.clear();
			this.resetUserData();
			// eslint-disable-next-line no-console
			console.log("error sign out!", error, error.response);
			return false;
		}
	},

	resetUserData() {
		const {setName, setEmail, setProfileImage, setAuthenticated,setToken, setUserToken,} = useUserRepository();

		setName("");
		setEmail("");
		setProfileImage("");
		setAuthenticated(false);
		setToken("");
		setUserToken("");
	},

	//eslint-disable-next-line
	register: async function (registerData: any) {
		try {
			const response = await ApiService.put("/register", registerData);
			const message = response.data.data.message;
			const token = response.data.data.token;
			const tokenLifeTime = response.data.data.tokenLifeTime ?? this.calculateTokenLifeTime();
			console.log(response, message, "TOKEN;",  token)
			if (token) {
				TokenService.saveToken(token);
				TokenService.saveRefreshToken(token);
				TokenService.setTokenLifeTime(tokenLifeTime);
				ApiService.setHeader();
			}
			ApiService.mount401Interceptor();
			return token;
		} catch (error) {
			this.catchError(error);
		}
	},

	//eslint-disable-next-line
	resetPassword: async function (resetPasswordData: any) {
		try {
			const response = await ApiService.post("/reset/password", resetPasswordData);
			return !!response.data;
		} catch (error) {
			this.catchError(error);
		}
	},

	sendResetPasswordLink: async function (email: string) {
		try {
			const resetPasswordData = {email: email};
			const response = await ApiService.post("/reset/sendResetLink", resetPasswordData);
			console.log("fooo",response);
			return response;
		} catch (error) {
			this.catchError(error);
		}
	},

	hasValidResetToken: async function (resetPasswordData: any) {
		try {
			const response = await ApiService.post("/reset/forgot-password/checkToken", resetPasswordData);
			return !!response.data;
		} catch (error) {
			this.catchError(error);
		}
	},

	//eslint-disable-next-line
	catchError: function (error: any) {
		let status;
		let description;

		if (error.response === undefined) {
			status = error.message;
			description = error.message;
		} else {
			status = error.response.status;
			description = error.response.data.error;
		}

		throw new AuthenticationError(status, description);
	}
}

export {AuthService, AuthenticationError};