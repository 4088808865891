const auth = {
	namespaced: true,
	state: () => ({
		token: "",
		tokenLifeTime: Number => 0,
		authenticated: Boolean => false,
	}),
	mutations: {
		// eslint-disable-next-line
		token(state: any, token: string) {
			state.token = token;
		},
		// eslint-disable-next-line
		tokenLifeTime(state: any, tokenLifeTime: number) {
			state.tokenLifeTime = tokenLifeTime;
		},
		// eslint-disable-next-line
		authenticated(state: any, value: boolean) {
			state.authenticated = value;
		},
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		getToken: (state: any) => {
			return state.token;
		},

		// eslint-disable-next-line
		getTokenLifeTime: (state: any) => {
			if (state.tokenLifeTime) {
				return state.tokenLifeTime;
			} else {
				return 0;
			}
		},

		// eslint-disable-next-line
		getAuthenticated: (state: any) => {
			return state.authenticated;
		},

		// eslint-disable-next-line
		isAuthenticated: (state: any) => {
			return state.authenticated === true;
		},
	}
}

export default auth;