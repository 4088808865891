import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-4 rounded-3xl bg-white shadow-xl navbg " }
const _hoisted_2 = { class: "container mx-auto px-6 sm:px-0" }
const _hoisted_3 = { class: "p-4 sm:flex sm:mt-8" }
const _hoisted_4 = {
  style: {"font-family":"Nunito","font-weight":"600!important"},
  class: "sm:w-full px-0 flex flex-col md:flex-row justify-between"
}
const _hoisted_5 = { class: "mt-0 md:mt-2 mb-4 text-xs text-gray-50 dark:text-gray-700 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageSelector = _resolveComponent("LanguageSelector")
  const _component_FooterColumnLayout = _resolveComponent("FooterColumnLayout")
  const _component_FooterRedirect = _resolveComponent("FooterRedirect")
  const _component_FooterItem = _resolveComponent("FooterItem")
  const _component_FooterLabel = _resolveComponent("FooterLabel")

  return (_openBlock(), _createBlock("footer", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_FooterColumnLayout, { padding: "mt-6 md:mt-0 md:px-6 lg:px-12 mb-6" }, {
            default: _withCtx(() => [
              _createVNode(_component_LanguageSelector)
            ]),
            _: 1
          }),
          _createVNode(_component_FooterColumnLayout, { "custom-flex": "flex lg:gap-2 flex-col lg:flex-row mb-6 md:mb-0 md:mt-1.5" }, {
            default: _withCtx(() => [
              _createVNode(_component_FooterRedirect, {
                "css-class": "py-2 md:py-0.5",
                "aria-label": "facebook",
                "item-icon-pre": "facebook_icon",
                "item-text": "",
                link: "https://www.facebook.com/boostpackApp"
              }),
              _createVNode(_component_FooterRedirect, {
                "css-class": "py-2 md:py-0.5",
                "aria-label": "instagram",
                "item-icon-pre": "instagram_icon",
                "item-text": "",
                link: "https://www.instagram.com/boostpack.app/"
              }),
              _createVNode(_component_FooterRedirect, {
                "css-class": "py-2 md:py-0.5",
                "aria-label": "twitter",
                "item-icon-pre": "twitter_icon",
                "item-text": "",
                link: "https://twitter.com/boostpack_app"
              }),
              _createVNode(_component_FooterRedirect, {
                "css-class": "py-2 md:py-0.5",
                "aria-label": "youtube",
                "item-icon-pre": "youtube_icon",
                "item-text": "",
                link: "https://www.youtube.com/channel/UC_r3YcmRA2lSZ5rE1Xty05A"
              })
            ]),
            _: 1
          }),
          _createVNode(_component_FooterColumnLayout, null, {
            default: _withCtx(() => [
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.faqTitle'),
                link: "/faq"
              }, null, 8, ["item-text"]),
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.supportTitle'),
                link: "/support"
              }, null, 8, ["item-text"]),
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.databaseTitle'),
                link: "/database-overview"
              }, null, 8, ["item-text"])
            ]),
            _: 1
          }),
          _createVNode(_component_FooterColumnLayout, null, {
            default: _withCtx(() => [
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.howToTitle'),
                link: "/how-to"
              }, null, 8, ["item-text"]),
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.contentGuideTitle'),
                link: "/content-guide"
              }, null, 8, ["item-text"]),
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.developerGuideTitle'),
                link: "/developer-guide"
              }, null, 8, ["item-text"])
            ]),
            _: 1
          }),
          _createVNode(_component_FooterColumnLayout, null, {
            default: _withCtx(() => [
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.imprintTitle'),
                link: "/imprint"
              }, null, 8, ["item-text"]),
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.privacyTitle'),
                link: "/privacy"
              }, null, 8, ["item-text"]),
              _createVNode(_component_FooterItem, {
                "item-text": _ctx.$t('nav.Footer.termsTitle'),
                link: "/terms"
              }, null, 8, ["item-text"])
            ]),
            _: 1
          }),
          _createVNode(_component_FooterColumnLayout, { class: "mt-6 md:mt-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_FooterLabel, { "item-text": _ctx.companyName }, null, 8, ["item-text"]),
              _createVNode("p", _hoisted_5, [
                _createVNode("a", {
                  href: "https://theconcept-technologies.com",
                  title: _ctx.currentEnvironment()
                }, _toDisplayString(_ctx.$t("nav.Footer.copyRightTcText")), 9, ["title"])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}