import {ProfileType} from "@/services/api/types/user.interface";
import {store} from "@/store";

//TODO use UserType
interface User {
	fullName: string;
	username: string;
	avatar_url: string;
	email: string;
	token: string;
	initials: string;
	profile: ProfileType;
}

const user = {
	namespaced: true,
	state: () => ({
		userData: {} as () => User,
	}),
	mutations: {
		// eslint-disable-next-line
		name(state: any, name: string) {
			state.userData.fullName = name;
		},
		// eslint-disable-next-line
		email(state: any, email: string) {
			state.userData.email = email;
		},
		// eslint-disable-next-line
		profileImage(state: any, profileImage: string) {
			state.userData.profileImage = profileImage;
		},
		// eslint-disable-next-line
		profile(state: any, profile?: ProfileType) {
			state.userData.profile = profile;
		},
		// eslint-disable-next-line
		token(state: any, token: string) {
			state.userData.token = token;
		},
		// eslint-disable-next-line
		initials(state: any, initials: string) {
			state.userData.initials = initials;
		},
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		fullName: (state: any) => {
			if (state.userData.profile && state.userData.profile.first_name && state.userData.profile.last_name) {
				return state.userData.profile.first_name + " " + state.userData.profile.last_name;
			} else {
				return state.userData.fullName;
			}
		},

		// eslint-disable-next-line
		getUserInitials: (state: any) => {
			if ((!state.userData.initials || state.userData.initials.length === 0) && state.userData.fullName) {
				const parts = state.userData.fullName.split(' ')
				let initials = '';
				for (let i = 0; i < parts.length; i++) {
					if (parts[i].length > 0 && parts[i] !== '') {
						initials += parts[i][0]
					}
				}
				store.commit('user/initials', initials);
			}
			return state.userData.initials;
		},

		// eslint-disable-next-line
		getEmail: (state: any) => {
			return state.userData.email;
		},

		// eslint-disable-next-line
		getProfileImage: (state: any) => {
			return state.userData.profileImage;
		},

		// eslint-disable-next-line
		getProfile: (state: any) => {
			return state.userData.profile;
		},

		// eslint-disable-next-line
		getToken: (state: any) => {
			if (state.userData.token) {
				return state.userData.token;
			} else {
				return null;
			}
		},

		// eslint-disable-next-line
		getUserByToken: (state: any) => (token: string) => {
			if (state.userData.token === token) {
				return state.userData;
			}
		},
	}
}

export default user;