
import {defineComponent} from 'vue';
import BaseAlertComponent from "@/components/Alert/Base/BaseAlertComponent.vue";

export default defineComponent({
	name: 'AlertComponent',
	components: {BaseAlertComponent},
	props: {},
	emits: [],
	setup(props, {emit}) {
		return {};
	}
})
