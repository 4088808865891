import {BoostpackItem} from "@/services/boostpack/boostpack_item.type";


export default function useBoostpackItemHelper() {

	function getAltImageTextByBoostpackItem(boostpackItem: BoostpackItem, categoryTitle = ""): string {
		let result = "";
		try {
			const boostpackItemData = JSON.parse(boostpackItem.data);
			if (boostpackItemData) {
				if (boostpackItemData.title) {
					result += "quote: " + boostpackItemData.title + "|author: " + boostpackItemData.description;
				}
			} else {
				result = boostpackItem.reference;
			}
			if (categoryTitle) {
				result += "|category: " + categoryTitle;
			}
		} catch (e) {
			console.log(e);
		}
		return result;
	}

	function getAltImageTextByTitleDescriptionAndCategory(title: string, description: string, categoryTitle = ""): string {
		let result = "";
		try {
			result += "quote: " + title + "|author: " + description;
			if (categoryTitle) {
				result += "|category: " + categoryTitle;
			}
		} catch (e) {
			console.log(e);
		}
		return result;
	}

	return {
		getAltImageTextByBoostpackItem,
		getAltImageTextByTitleDescriptionAndCategory
	}
}