
import {defineComponent, ref} from 'vue';
import useUserRepository from "@/composable/user/useUserRepository";
import router from "@/router";
import HeaderItem from "@/components/Nav/Header/HeaderItem.vue";
import HeaderLogo from "@/components/Nav/Header/HeaderLogo.vue";
import RatingDialog from "@/components/Modal/RatingDialog.vue";
import AvatarMenuItem from "@/components/Nav/Header/AvatarMenuItem.vue";

export default defineComponent({
  name: 'Header',
  components: {AvatarMenuItem, RatingDialog, HeaderLogo, HeaderItem},
  props: {},
  emits: [],
  setup() {
    const {getFullName, getUserImage, getUserEmail, isAuthenticated, getUserInitials} = useUserRepository();

    function logout() {
      router.push('/logout');
    }

    return {
      getFullName,
      getUserImage,
      getUserEmail,
      isAuthenticated,
      getUserInitials,
      logout,
    }
  },
  methods: {}
})
