import {AlertInterface} from "@/types/alert.interface";
import {nanoid} from 'nanoid'
import {AlertTypes} from "@/composable/alert/useAlerts";

const alert = {
	namespaced: true,
	state: () => ({
		errors: [],
		infos: [],
		successes: [],
		warnings: [],
		secondaries: [],
	}),
	mutations: {
		// eslint-disable-next-line
		addErrorAlert(state: any, alert: AlertInterface) {
			alert.id = nanoid();
			state.errors.push(alert);
		},
		// eslint-disable-next-line
		addInfoAlert(state: any, alert: AlertInterface) {
			alert.id = nanoid();
			state.infos.push(alert);
		},
		// eslint-disable-next-line
		addSuccessAlert(state: any, alert: AlertInterface) {
			alert.id = nanoid();
			state.successes.push(alert);
		},
		// eslint-disable-next-line
		addWarningAlert(state: any, alert: AlertInterface) {
			alert.id = nanoid();
			state.warnings.push(alert);
		},
		// eslint-disable-next-line
		addSecondaryAlert(state: any, alert: AlertInterface) {
			alert.id = nanoid();
			state.secondaries.push(alert);
		},
		// eslint-disable-next-line
		removeAlertByType(state: any, type: string){
			if (type === "error") {
				state.errors = [];
			}
			if (type === "success") {
				state.successes = [];
			}
			if (type === "warning") {
				state.warnings = [];
			}
			if (type === "secondary") {
				state.secondaries = [];
			}
		},
		// eslint-disable-next-line
		removeAlert(state: any, alertId: string) {
			let removeIndex = null;
			if (state.errors.length > 0) {
				removeIndex = state.errors.map(function (item) {
					return item.id;
				}).indexOf(alertId);
				if (removeIndex) {
					state.errors.splice(removeIndex, 1);
					return true;
				}
			}

			if (state.warnings.length > 0) {
				removeIndex = state.warnings.map(function (item) {
					return item.id;
				}).indexOf(alertId);
				if (removeIndex) {
					state.warnings.splice(removeIndex, 1);
					return true;
				}
			}

			if (state.successes.length > 0) {
				removeIndex = state.successes.map(function (item) {
					return item.id;
				}).indexOf(alertId);
				if (removeIndex) {
					state.successes.splice(removeIndex, 1);
					return true;
				}
			}

			if (state.infos.length > 0) {
				removeIndex = state.infos.map(function (item) {
					return item.id;
				}).indexOf(alertId);
				if (removeIndex) {
					state.infos.splice(removeIndex, 1);
					return true;
				}
			}

			if (state.secondaries.length > 0) {
				removeIndex = state.secondaries.map(function (item) {
					return item.id;
				}).indexOf(alertId);
				if (removeIndex) {
					state.secondaries.splice(removeIndex, 1);
					return true;
				}
			}
		}
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		getErrors: (state: any) => {
			return state.errors;
		},
		// eslint-disable-next-line
		getInfos: (state: any) => {
			return state.infos;
		},
		// eslint-disable-next-line
		getSuccesses: (state: any) => {
			return state.successes;
		},
		// eslint-disable-next-line
		getWarnings: (state: any) => {
			return state.warnings;
		},
		// eslint-disable-next-line
		getSecondaries: (state: any) => {
			return state.secondaries;
		},
		// eslint-disable-next-line
		getAllAlerts: (state: any) => (type: string) => {
			return {
				error: state.errors,
				success: state.successes,
				warning: state.warnings,
				secondary: state.secondaries,
			}
		},
	}
}

export default alert;