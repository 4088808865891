
import {defineComponent, onMounted, onDeactivated, ref, toRef, watch} from 'vue';
import {useRoute} from 'vue-router'
import {nanoid} from 'nanoid'
import DefaultButton from "@/components/Button/DefaultButton.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import DefaultCardLayout from "@/components/Card/DefaultCardLayout.vue";
import useCustomStore from "@/composable/custom/useCustomStore";

export default defineComponent({
	name: 'AdBlockerDialog',
	components: {DefaultCardLayout, BtnClose, DefaultButton},
	props: {
		id: {type: String, default: nanoid()},
		title: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
		showButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const route = useRoute();
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const dialogVisible = ref(visible.value);
		const {getAdBlocker, setAdBlocker, isAdBlockerCheckRunning, setAdBlockerCheckRunning, isFullCookieConsentAllowed} = useCustomStore();
		const adblockEnabled = ref(getAdBlocker);
		const adBlockIntervalId: any = ref(null);

		function checkAdBlocker(timeout = 1000, forceCheck = false) {
			const currentRoute = window.location.href;

			const excludeRoutes = ["Home", "/", "Landing"];
			const routeName: any|string = route.name;

			if (!isFullCookieConsentAllowed()) {
				setAdBlockerCheckRunning(false);
				setAdBlocker(false);
				dialogVisible.value = false;
				return true;
			}

			if ((getAdBlocker.value || forceCheck) && !isAdBlockerCheckRunning.value && !excludeRoutes.includes(routeName)) {
				setAdBlockerCheckRunning(true);
				setTimeout(function () {
					try {
						if (currentRoute !== window.location.href) {
							setAdBlockerCheckRunning(false);
							setAdBlocker(false);
							dialogVisible.value = false;
						} else {
							const adsTestRequest = new Request(
								"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
								// "https://www.googletagmanager.com/gtag/js?id=G-NWM6K9VM1T",
								// "https://static.ads-twitter.com/uwt.js",
								{ method: "HEAD", mode: "no-cors" }
							);
							fetch(adsTestRequest).then((res) => {
								setAdBlocker(false);
								dialogVisible.value = false;
								setAdBlockerCheckRunning(false);
							}).catch((err) => {
								setAdBlocker(true);
								dialogVisible.value = true;
								setAdBlockerCheckRunning(false);
							});
						}
					} catch (e) {
						console.log(e);
					} finally {
						setAdBlockerCheckRunning(false);
					}
				}, timeout);
			}
		}

		function hasAdblockerActivated(): boolean {
			return adblockEnabled.value === true;
		}

		function hideDialog() {
			checkAdBlocker(1000);
		}

		function toggleDialog(timeout = 2000) {
			checkAdBlocker(timeout);
		}

		function cancelDialog() {
			dialogVisible.value = false;
			emit('cancelConfirm');
			setAdBlocker(false);
			toggleDialog();
		}

		const confirm = () => {
			dialogVisible.value = false;
			emit('confirm');
			toggleDialog();
		};

		watch(() => route.path, (newValue: string, oldValue: string) => {
			if (newValue !== oldValue) {
				checkAdBlocker();
			}
		});

		onMounted(() => {
			window.onload = function () {
				setAdBlockerCheckRunning(false);
				toggleDialog(8000);
			};
			if (!adBlockIntervalId.value) {
				adBlockIntervalId.value = setInterval(function () {
					checkAdBlocker(1000, true);
				}, 20000);
			}
		});



		onDeactivated(() => {
			if (adBlockIntervalId.value) {
				clearInterval(adBlockIntervalId.value);
			}
		});

		return {
			dialogTitle,
			dialogDescription,
			dialogVisible,
			toggleDialog,
			hideDialog,
			confirm,
			cancelDialog,
			hasAdblockerActivated,
		}
	},
})
