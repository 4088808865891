import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import {store, key} from './store'
import './assets/styles/index.css';
import ApiService from "@/services/api/base/api.service";
import createI18n from './i18n';

const app = createApp(App);
app.use(createI18n);
app.use(store, key);
app.use(router);
app.mount('#app');

ApiService.init(process.env.VUE_APP_API_STABLE_URL);
