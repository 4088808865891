
import {defineComponent} from 'vue';
import {useVModel} from '@/composable/input/useVModel.js';
import {ExclamationCircleIcon} from '@heroicons/vue/solid'

export default defineComponent({
	name: 'InputArea',
	components: {ExclamationCircleIcon},
	emits: ['update:email', 'update:password'],
	props: {
		value: String,
		inputType: {
			type: String,
			default: "input"
		},
		inputPlaceholder: {
			type: String,
			default: "Input"
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		iWidth: {
			type: String,
			default: 'w-full sm:w-auto',
		},
		iHeight: {
			type: String,
			default: 'h-12',
		},
		iRounded: {
			type: String,
			default: 'rounded-3xl',
		},
		textPosition: {
			type: String,
			default: 'text-center',
		},
		iPadding: {
			type: String,
			default: 'p-2 md:p-1.5',
		},
		cssClass: {
			type: String,
			default: 'my-2 sm:m-2',
		},
		errorMessage: {
			type: String,
			default: ""
		},
		showLabel: {
			type: Boolean,
			default: false,
		},
		rowCount: {
			type: Number,
			default: 4,
		},
		colCount: {
			type: Number,
			default: 50,
		},
	},
	setup(props) {
		return {
			valueState: useVModel(props, 'value'),
		};
	}
})
