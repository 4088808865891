
import {computed, defineComponent, reactive, ref, toRef} from 'vue';
import {nanoid} from 'nanoid'
import DefaultButton from "@/components/Button/DefaultButton.vue";
import RatingIcons from "@/components/Icon/RatingIcons.vue";
import BtnClose from "@/components/Nav/BtnClose.vue";
import InputArea from "@/components/Input/InputArea.vue";
import InputField from "@/components/Input/InputField.vue";
import {store} from "@/store";
import {RatingService} from "@/services/api/rating/rating.service";
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: 'RatingDialog',
	components: { DefaultIcon, InputArea, InputField, BtnClose, RatingIcons, DefaultButton},
	props: {
		id: {type: String, default: nanoid()},
		title: {type: String, default: ""},
		description: {type: String, default: ""},
		visible: {type: Boolean, default: false},
		showButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['cancelDialog', 'sendDialog'],
	setup(props, {emit}) {

		const ratingStars = ref<InstanceType<typeof RatingIcons>>();
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const dialogVisible = ref(visible.value);

		const showSuccess = ref(false);
		const hasError = ref(false);
		const isButtonDisabled = ref(false);

		const hasUserRatedApplication = computed(() => store.getters['custom/hasUserRatedApplication']);

		const ratingData = reactive({
			email: "",
			description: "",
		});

		/**
		 *
		 */
		function isDialogVisible(): boolean {
			return dialogVisible.value === true;
		}

		function toggleDialog() {
			dialogVisible.value = !dialogVisible.value;
		}

		function hideDialog() {
			dialogVisible.value = false;
		}

		function cancelDialog() {
			dialogVisible.value = false;
			emit('cancelDialog');
		}

		async function onSubmit() {
			/* eslint-disable @typescript-eslint/camelcase */
			isButtonDisabled.value = true;
			const ratingDataForRequest = {
				rater_reference: ratingData.email,
				rating_value: ratingStars.value?.getStars() ?? 0,
				description: ratingData.description
			};
			const ratingResponse = await RatingService.create(ratingDataForRequest);
			if (ratingResponse) {
				if (ratingResponse.status === 200) {
					hasError.value = false;
					showSuccess.value = true;
					setTimeout(function () {
						isButtonDisabled.value = false;
						cancelDialog();
					}, 5000);
				} else {
					showSuccess.value = false;
					hasError.value = true;
					isButtonDisabled.value = false;
				}
				//TODO check response
			} else {
				showSuccess.value = false;
				hasError.value = true;
				isButtonDisabled.value = false;
			}
		}

		return {
			dialogTitle,
			dialogDescription,
			dialogVisible,
			toggleDialog,
			hideDialog,
			onSubmit,
			cancelDialog,
			isDialogVisible,
			ratingData,
			hasUserRatedApplication,
			ratingStars,
			showSuccess,
			hasError,
			isButtonDisabled,
		}
	},
	methods: {}
})
