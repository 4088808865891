import {store} from "@/store";

const TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {

	getToken() {
		const userToken = store.getters['auth/getToken'] ?? null;
		if (userToken) {
			return userToken;
		} else {
			return null;
		}
	},

	saveToken(accessToken: string) {
		store.commit('auth/token', accessToken);
	},

	setTokenLifeTime(tokenLifeTime: number) {
		store.commit('auth/tokenLifeTime', tokenLifeTime);
	},

	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
		store.commit('auth/authenticated', false);
	},

	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},

	saveRefreshToken(refreshToken: string) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},

	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}
};

export {TokenService};