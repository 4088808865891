import {MediaItem} from "@/services/boostpack/media/media_item.type";

export class MediaItemList extends Array<MediaItem> {

	constructor(items: MediaItem[] = []) {
		super();
		this.push(...items);
	}

	add(items: MediaItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): MediaItem {
		return this[index];
	}
}