const custom = {
	namespaced: true,
	state: () => ({
		userApplicationRating: false,
		pageReload: false,
		adBlockEnabled: true,
		adBlockerCheckRunning: false,
		adBlockerCheckUrl: "",
		cookiesAccepted: false,
		languageLocale: '',
		// languageLocale: process.env.VUE_APP_I18N_LOCALE || 'en',
		cookieDetails: {
			all: false,
			minimal: false,
		}
	}),
	mutations: {
		// eslint-disable-next-line
		setUserApplicationRating(state: any, value: boolean) {
			state.userApplicationRating = value;
		},
		// eslint-disable-next-line
		setPageReload(state: any, value: boolean) {
			state.pageReload = value;
		},
		// eslint-disable-next-line
		setAdBlocker(state: any, value: boolean) {
			state.adBlockEnabled = value;
		},
		// eslint-disable-next-line
		setAdBlockerCheckRunning(state: any, value: boolean) {
			state.adBlockerCheckRunning = value;
		},
		// eslint-disable-next-line
		setAdBlockerCheckUrl(state: any, value: string) {
			state.adBlockerCheckUrl = value;
		},
		// eslint-disable-next-line
		setCookies(state: any, value: boolean) {
			state.cookiesAccepted = value;
		},
		// eslint-disable-next-line
		setCookieDetails(state: any, value: {}) {
			state.cookieDetails = value;
		},
		// eslint-disable-next-line
		setLanguageLocale(state: any, value: {}) {
			state.languageLocale = value;
		},
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		getPageReload: (state: any) => {
			return state.pageReload;
		},
		// eslint-disable-next-line
		getAdBlocker: (state: any) => {
			return state.adBlockEnabled;
		},
		// eslint-disable-next-line
		getAdBlockerCheckRunning: (state: any) => {
			return state.adBlockerCheckRunning;
		},
		// eslint-disable-next-line
		getAdBlockerCheckUrl: (state: any) => {
			return state.adBlockerCheckUrl;
		},
		// eslint-disable-next-line
		getCookies: (state: any) => {
			return state.cookiesAccepted;
		},
		// eslint-disable-next-line
		getCookieDetails: (state: any) => {
			return state.cookieDetails;
		},
		// eslint-disable-next-line
		getLanguageLocale: (state: any) => {
			return state.languageLocale;
		},
		// eslint-disable-next-line
		hasUserRatedApplication: (state: any) => {
			return state.userApplicationRating;
		},
	}
}

export default custom;