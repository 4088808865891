import { InjectionKey, State } from 'vue'
import { createStore,  useStore as baseUseStore, createLogger, Store } from 'vuex'
import checkout from "@/store/modules/checkout";
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import alert from "@/store/modules/alert";
import boostpack from "@/store/modules/boostpack";
import custom from "@/store/modules/custom";
import VuexPersistence from 'vuex-persist'

const debug = process.env.NODE_ENV !== 'production'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export const key: InjectionKey<Store<State>> = Symbol()

// export default createStore({
export const store = createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    user,
    checkout,
    alert,
    boostpack,
    custom,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
});

// export default createStore({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//     auth,
//     user,
//     checkout,
//     alert,
//   },
//   strict: debug,
//   plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
// })


// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}
