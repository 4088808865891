import {BoostpackInterface} from "@/services/boostpack/boostpack.interface";
import {CategoryInterface} from "@/services/boostpack/category/category.interface";
import {BoostpackItemList} from "@/services/boostpack/boostpack_item_list.type";
import {UserInterface} from "@/services/boostpack/user/user.interface";

export class Boostpack implements BoostpackInterface {

	active: boolean;
	boostpackItems: BoostpackItemList;
	category: CategoryInterface;
	createdAt: Date;
	data: any;
	description: string;
	id: number;
	netAmount: number;
	shortDescription: string;
	state: string;
	taxAmount: number;
	taxCode: string;
	title: string;
	totalAmount: number;
	updatedAt: Date;
	userId: number;
	user: UserInterface;
	period?;
	reference?;
	// user: UserType

	constructor(active: boolean, boostpackItems: BoostpackItemList, category: CategoryInterface, createdAt: Date, data: any, description: string, id: number, netAmount: number, shortDescription: string, state: string, taxAmount: number, taxCode: string, title: string, totalAmount: number, updatedAt: Date, userId: number, user: UserInterface, period?: string | null, reference?: string | null) {
		this.active = active;
		this.boostpackItems = boostpackItems;
		this.category = category;
		this.createdAt = createdAt;
		this.data = data;
		this.description = description;
		this.id = id;
		this.netAmount = netAmount;
		this.shortDescription = shortDescription;
		this.state = state;
		this.taxAmount = taxAmount;
		this.taxCode = taxCode;
		this.title = title;
		this.totalAmount = totalAmount;
		this.updatedAt = updatedAt;
		this.userId = userId;
		this.user = user;
		this.period = period;
		this.reference = reference;
	}

}