
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'FooterColumnLayout',
	components: {},
	props: {
    padding: {
      type: String,
      default: "md:pr-6 lg:pr-12"
    },
    headerText: {
      type: String,
      default: ""
    },
    customFlex: {
      type: String,
      default: "flex flex-col "
    },
  },
	emits: [],
	setup() {
		return {}
	},
})
