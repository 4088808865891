import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import Landing from '../views/Landing.vue';
import {store} from "@/store";
import authMiddleware from "@/router/middleware/auth";
import middlewarePipeline from "@/router/middlewarePipeline";

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Boostpack | find Motivation Images',
		component: Landing,
		meta: {
			title: "Boostpack | find Motivation Images",
			metaTags: [
				{
					name: 'Boostpack | find Motivation Images',
					content: 'Get Inspired and grow new habits. Choose your Boostpack and get daily motivation quotes and content on your preferred channel. Start today and grow for tomorrow.'
					// content: 'FooBAR' + i18n.global.t("auth.RegisterComponent.registerTitle") + ', Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'og:description',
					content: 'Get Inspired and grow new habits. Choose your Boostpack and get daily motivation quotes and content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'description',
					content: 'Get Inspired and grow new habits. Choose your Boostpack and get daily motivation quotes and content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					name: 'keywords',
					content: 'motivation, boostpack, quotes, booster pack, BoostPack, quote pictures, inspirational quotes, pinterest daily quotes, daily motivation, ' +
						'generate quotes, quotes for my business, grow new habits, boostech, quote journey, brainy quote, quote karma, quote learning, morning motivation, goodreads quotes, instagram quote pictures, quotes creator, zen quote, social media quotes, motivation letter, daily mail, increase habits, '
				}
			],
		}
	},
	{
		path: '/how-to',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/About.vue'),
		meta: {
			title: "Boostpack | About",
			metaTags: [
				{
					name: 'Boostpack | About',
					content: 'Understand witch Content you can share on Boostpack, how to you create it and finaly share it with other Users. Start giving positiv Impact to the World and create Booster Packs for People.'
				},
				{
					property: 'og:description',
					content: 'Understand witch Content you can share on Boostpack, how to you create it and finaly share it with other Users. Start giving positiv Impact to the World and create Booster Packs for People.'
				},
				{
					property: 'description',
					content: 'Understand witch Content you can share on Boostpack, how to you create it and finaly share it with other Users. Start giving positiv Impact to the World and create Booster Packs for People.'
				},
				{
					name: 'keywords',
					content: 'how did boostpack work, what is boostpack, boostpack what is it, what can i do with boostpack, where can i find daily quotes, how to create quote pictures, a service for quotes, generate quotes'
				}
			],
		}
	},
	{
		path: '/content-guide',
		name: 'ContentGuide',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "contentGuide" */ '../views/ContentGuide.vue'),
		meta: {
			title: "Boostpack | Content Guide",
			metaTags: [
				{
					name: 'Boostpack | Content Guide',
					content: 'The Content on Boostpack is the heart of the Service, It\'s important that Users receive Quality Content. Learn more'
				},
				{
					property: 'og:description',
					content: 'The Content on Boostpack is the heart of the Service, It\'s important that Users receive Quality Content. Learn more'
				},
				{
					property: 'description',
					content: 'The Content on Boostpack is the heart of the Service, It\'s important that Users receive Quality Content. Learn more'
				},
				{
					name: 'keywords',
					content: 'why was my boostpack rejected, content guides boostpack, whats allowed at boostpack,'
				},
			],
		}
	},

	{
		path: '/imprint',
		name: 'Imprint',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue'),
		meta: {
			title: "Boostpack | Imprint",
			metaTags: [
				{
					name: 'Boostpack | Imprint',
					content: 'Visit theconcept-technologies.com for further Information about and how we try to give positive impact to the daily challenging world.'
				},
				{
					property: 'og:description',
					content: 'Visit theconcept-technologies.com for further Information about and how we try to give positive impact to the daily challenging world.'
				},
				{
					property: 'description',
					content: 'Visit theconcept-technologies.com for further Information about and how we try to give positive impact to the daily challenging world.'
				},
				{
					name: 'keywords',
					content: 'motivation, boostpack, quotes, quote pictures,inspirational quotes, pinterest daily quotes, daily motivation, ' +
						'whats the company behind boostpack, theconcept, where can i find contact data from boostpack,'
				},
			],
		}
	},
	{
		path: '/privacy',
		name: 'Privacy',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
		meta: {
			title: "Privacy",
			metaTags: [
				{
					name: 'Boostpack | Privacy',
					content: 'How we proceed Data and assure that data is stored secure and anonymized. So you can focus on your Personal Grow and not the Privacy Rules.'
				},
				{
					property: 'og:description',
					content: 'How we proceed Data and assure that data is stored secure and anonymized. So you can focus on your Personal Grow and not the Privacy Rules.'
				},
				{
					property: 'description',
					content: 'How we proceed Data and assure that data is stored secure and anonymized. So you can focus on your Personal Grow and not the Privacy Rules.'
				},
				{
					name: 'keywords',
					content: 'privacy boostpack, what did boostpack store, data protection boostpack,'
				},
			],

		}
	},
	{
		path: '/terms',
		name: 'Terms',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
		meta: {
			title: "Terms",
			metaTags: [
				{
					name: 'Boostpack | Terms',
					content: 'these Terms of Use constitute a legally binding agreement made between you, and theconcept e.U. concerning your access to our Service. Read more:'
				},
				{
					property: 'og:description',
					content: 'these Terms of Use constitute a legally binding agreement made between you, and theconcept e.U. concerning your access to our Service. Read more:'
				},
				{
					property: 'description',
					content: 'these Terms of Use constitute a legally binding agreement made between you, and theconcept e.U. concerning your access to our Service. Read more:'
				},
				{
					name: 'keywords',
					content: 'terms of boostpack, what is allowed at boostpack, can i delete boostpacks, how to create a business profile at boostpack,'
				},
			],

		}
	},
	// {
	// 	path: '/blog',
	// 	name: 'Blog',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
	// 	meta: {
	// 		title: "Blog",
	// 		metaTags: [
	// 			{
	// 				name: 'Boostpack | Blog',
	// 				content: 'Get the latest News about Motivation and Inspiring Content and how Boostpack could help you to grow new Habits. Visit the Blog.'
	// 			},
	// 			{
	// 				property: 'og:description',
	// 				content: 'Get the latest News about Motivation and Inspiring Content and how Boostpack could help you to grow new Habits. Visit the Blog.'
	// 			},
	// 			{
	// 				name: 'keywords',
	// 				content: 'learn more about boostpack, news boostpack, info boostpack,'
	// 			},
	// 		],
	// 	}
	// },
	{
		path: '/database-overview',
		name: 'Database Overview',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "database Overview" */ '../views/DatabaseOverview.vue'),
		meta: {
			title: "Database Overview",
			metaTags: [
				{
					name: 'Boostpack | Database Overview',
					content: ''
				},
				{
					property: 'og:description',
					content: ''
				},
				{
					property: 'description',
					content: ''
				},
				{
					name: 'keywords',
					content: ''
				},
			],

		}
	},
	{
		path: '/landing',
		name: 'Landing',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue'),
		meta: {
			title: "Boostpack | find Motivational Images",
			metaTags: [
				{
					name: 'description',
					content: 'Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'og:description',
					content: 'Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					property: 'description',
					content: 'Get Inspired and grow new habits. Choose your Boostpack and get daily motivation content on your preferred channel. Start today and grow for tomorrow.'
				},
				{
					name: 'keywords',
					content: 'motivation, motivational images, boostpack quotes, quote, picture quote, inspirational quote, pinterest daily quote, daily motivation, ' +
						'generate quote, business quotes, create new habit, journey quote, love quotes, quotes philosophic, quote friends, morning quotes, goodreads quote, instagram quotes, quote creator,self improvement quote, social media quote, find motivation, daily quote receive, increase new habit, '
				},
			],
		}
	},
	{
		path: '/profile',
		name: 'Profile',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Profile.vue'),
		meta: {
			title: "Boostpack | Profile",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Login to get more out of Boostpack. Manage your Boostpacks and change subscriptions plans or create your own Boostpack to share with the Community'
				},
				{
					property: 'og:description',
					content: 'Login to get more out of Boostpack. Manage your Boostpacks and change subscriptions plans or create your own Boostpack to share with the Community'
				},
				{
					property: 'description',
					content: 'Login to get more out of Boostpack. Manage your Boostpacks and change subscriptions plans or create your own Boostpack to share with the Community'
				},
				{
					name: 'keywords',
					content: 'quotes for mailing list, create quotes with csv, create quotes for my business, create quotes with logo, create quote images, create quote pictures, find quotes, quote for my social media business,'
				},
			],

		}
	},
	{
		path: '/login',
		name: 'Login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Auth/LoginComponent.vue'),
		meta: {
			title: "Boostpack | Login",
			metaTags: [
				{
					name: 'description',
					content: 'Log in to get access to all functions like save, share and create Boostpacks. Create your Profile and start boosting your Life.'
				},
				{
					property: 'og:description',
					content: 'Log in to get access to all functions like save, share and create Boostpacks. Create your Profile and start boosting your Life.'
				},
				{
					property: 'description',
					content: 'Log in to get access to all functions like save, share and create Boostpacks. Create your Profile and start boosting your Life.'
				},
				{
					name: 'keywords',
					content: 'create quote pictures for myself, create pictures with text, create pictures with logo, generate quote pictures, generate motivational content, delete watermark from boostpack images,'
				},
			],
		}
	},
	{
		path: '/developer-guide',
		name: 'DeveloperGuide',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "developerGuide" */ '../views/DeveloperGuide.vue'),
		meta: {
			title: "Boostpack | Developer Guide",
			metaTags: [
				{
					name: 'description',
					content: 'How can you use our APi to include Content into your Project and how you can Boost your Application with Boostpack. Learn more in the Documentation.'
				},
				{
					property: 'og:description',
					content: 'How can you use our APi to include Content into your Project and how you can Boost your Application with Boostpack. Learn more in the Documentation.'
				},
				{
					property: 'description',
					content: 'How can you use our APi to include Content into your Project and how you can Boost your Application with Boostpack. Learn more in the Documentation.'
				},
				{
					name: 'keywords',
					content: 'api boostpack, rest api boostpack, usefull api for my application, apis i can use for my app,'
				},
			],
		}
	},
	{
		path: '/faq',
		name: 'Faq',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue'),
		meta: {
			title: "Boostpack | FAQ",
			metaTags: [
				{
					name: 'description',
					content: 'Understand the Service, and all it\'s Benefits for your Live. How does Boostpack work?, What Channels could I use to receive Boostpacks?'
				},
				{
					property: 'og:description',
					content: 'Understand the Service, and all it\'s Benefits for your Live. How does Boostpack work?, What Channels could I use to receive Boostpacks?'
				},
				{
					property: 'description',
					content: 'Understand the Service, and all it\'s Benefits for your Live. How does Boostpack work?, What Channels could I use to receive Boostpacks?'
				},
				{
					name: 'keywords',
					content: 'how can i benefit from boostpack, how can i grow motivation, is there a service to create quote pictures, is there a service to generate quotes,'
				},
			],
		}
	},
	{
		path: '/support',
		name: 'Support',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
		meta: {
			title: "Boostpack | Support",
			metaTags: [
				{
					name: 'description',
					content: 'Contact the Support of Boostpack if you had any Questions about our Service or any Issues we can help you to solve.'
				},
				{
					property: 'og:description',
					content: 'Contact the Support of Boostpack if you had any Questions about our Service or any Issues we can help you to solve.'
				},
				{
					property: 'description',
					content: 'Contact the Support of Boostpack if you had any Questions about our Service or any Issues we can help you to solve.'
				},
				{
					name: 'keywords',
					content: 'boostpack i need support, support for my quote images, contact boostpack team,'
				},
			],
		}
	},
	{
		path: '/forgot-password',
		name: 'Forgot Password',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Auth/ForgotPasswordComponent.vue'),
		meta: {
			title: "Boostpack | Forgot Password?",
			metaTags: [
				{
					name: 'description',
					content: 'Change your Password.'
				},
				{
					property: 'og:description',
					content: 'Change your Password.'
				},
				{
					property: 'description',
					content: 'Change your Password.'
				},
				{
					name: 'keywords',
					content: 'how can i change my password at boostpack, i forgot my password at boostpack,creator,'
				},
			],
		}
	},
	{
		path: '/register',
		name: 'Register',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Auth/RegisterComponent.vue'),
		meta: {
			title: "Boostpack | Register",
			metaTags: [
				{
					name: 'description',
					content: 'Register and create a new boostpack Account.'
				},
				{
					property: 'og:description',
					content: 'Register and create a new boostpack Account.'
				},
				{
					property: 'description',
					content: 'Register and create a new boostpack Account.'
				},
				{
					name: 'keywords',
					content: 'create quotes for my company, how can i register for boostpack, how much does boostpack business cost, can i create a private profile at boostpack, generate quote pictures for myself, generate pictures with text, generate pictures with logo,'
				},
			],
		}
	},
	{
		path: '/password-reset',
		name: 'Reset Password',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Auth/ResetPasswordComponent.vue'),
		meta: {
			title: "Boostpack | Forgot Password?",
			metaTags: [
				{
					name: 'description',
					content: 'Change your Password.'
				},
				{
					property: 'og:description',
					content: 'Change your Password.'
				},
				{
					property: 'description',
					content: 'Change your Password.'
				},
				{
					name: 'keywords',
					content: 'reset password boostpack, forgot password boostpack, link to change password boostpack,'
				},
			],
		}
	},
	{
		path: '/auth/:provider/callback',
		component: {
			template: '<div class="auth-component"></div>'
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Logout.vue'),
		meta: {
			title: "Logout",
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/profile/shared',
		name: 'Shared',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/Shared.vue'),
		meta: {
			title: "Shared",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/profile/shared/create',
		name: 'Create Boostpack',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/createBoostpack1.vue'),
		meta: {
			title: "Create Boostpack",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/profile/shared/upload',
		name: 'Upload Boostpack',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/uploadBoostpack.vue'),
		meta: {
			title: "Upload Boostpack",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/profile/orders',
		name: 'Orders',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/Order/OrderList.vue'),
		meta: {
			title: "Orders",
			middleware: [
				authMiddleware
			],
		}
	},

	{
		path: '/profile/subscribed',
		name: 'Subscribed',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/Subscribed.vue'),
		meta: {
			title: "Subscribed",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/profile/settings',
		name: 'Settings',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/Settings.vue'),
		meta: {
			title: "Settings",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/profile/notifications',
		name: 'Notifications',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Auth" */ '../components/Profile/Notifications.vue'),
		meta: {
			title: "Notifications",
			middleware: [
				authMiddleware
			],
			metaTags: [
				{
					name: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'og:description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					property: 'description',
					content: 'Logout from the Service, You will automatically get logged out when you are inactive for more than 10 minutes.'
				},
				{
					name: 'keywords',
					content: 'logout boostpack,'
				},
			],
		}
	},

	{
		path: '/MailingSettings',
		name: 'Mailing Settings',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "MailingSettings" */ '../views/MailingSettings.vue'),
		meta: {
			title: "Mailing Settings",
			metaTags: [
				{
					name: 'description',
					content: 'Manage your Mailing Settings, or unsubscribe from a Boostpack'
				},
				{
					property: 'og:description',
					content: 'Manage your Mailing Settings, or unsubscribe from a Boostpack'
				},
				{
					property: 'description',
					content: 'Manage your Mailing Settings, or unsubscribe from a Boostpack'
				},
				{
					name: 'keywords',
					content: ''
				},
			],
		}
	},
	{
		path: '/feed',
		name: 'Feed',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "Feed" */ '../components/Boostpack/Feed.vue'),
		meta: {
			title: "Boostpack | Feed",
			metaTags: [
				{
					name: 'description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					property: 'og:description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					property: 'description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					name: 'keywords',
					content: 'motivation boost, motivational images, boost my hobby or habit, quotes from different categories, quotes for myself, subscribe daily quotes, quotes for my social media, find quote images, image feed, quote feed,'
				},
			],
		},
	},
	{
		path: "/feed/items",
		name: "Feed Items",
		component: () => import(/* webpackChunkName: "Feed" */ '../components/Boostpack/Feed.vue'),
			meta: {
				title: "Boostpack | Item Feed",
				metaTags: [
					{
						name: 'description',
						content: 'Search thousand of quote images for all Categories you can imagine, your daily Boost, visit and find yours.'
					},
					{
						property: 'og:description',
						content: 'Search thousand of quote images for all Categories you can imagine, your daily Boost, visit and find yours.'
					},
					{
						property: 'description',
						content: 'Search thousand of quote images for all Categories you can imagine, your daily Boost, visit and find yours.'
					},
					{
						name: 'keywords',
						content: 'motivational images, motivation images for life, life quotes, family quotes, motivation quotes, training quotes, bodybuilding quotes,'
					},
				],
			}
	},
	{
		path: "/feed/boostpacks",
		name: "Boostpack Feed",
		component: () => import(/* webpackChunkName: "Feed" */ '../components/Boostpack/Feed.vue'),
		meta: {
			title: "Boostpack | Feed",
			metaTags: [
				{
					name: 'description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					property: 'og:description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					property: 'description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					name: 'keywords',
					content: 'motivation boost, motivational images, boost my hobby or habit, quotes from different categories, quotes for myself, subscribe daily quotes, quotes for my social media, find quote images, image feed, quote feed,'
				},
			],
		},
	},
	// {
	// 	path: '/feed',
	// 	name: 'Feed',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	// component: () => import(/* webpackChunkName: "Feed" */ '../components/Boostpack/Feed.vue'),
	// 	component: Feed,
	// 	children: [
	// 		{
	// 			path: "items",
	// 			name: "Feed Items",
	// 			// component: () => import(/* webpackChunkName: "ItemFeed" */ '../components/Boostpack/BoostpackItemFeed.vue'),
	// 			component: BoostpackItemFeed,
	// 				meta: {
	// 					title: "Boostpack | Item Feed",
	// 					metaTags: [
	// 						{
	// 							name: 'description',
	// 							content: 'Search thousand of quote images for all Categories you can imagine, your daily Boost, visit and find yours.'
	// 						},
	// 						{
	// 							property: 'og:description',
	// 							content: 'Search thousand of quote images for all Categories you can imagine, your daily Boost, visit and find yours.'
	// 						},
	// 						{
	// 							property: 'description',
	// 							content: 'Search thousand of quote images for all Categories you can imagine, your daily Boost, visit and find yours.'
	// 						},
	// 						{
	// 							name: 'keywords',
	// 							content: 'motivational images, motivation images for life, life quotes, family quotes, motivation quotes, training quotes, bodybuilding quotes,'
	// 						},
	// 					],
	// 				}
	// 		},
	// 		{
	// 			path: "boostpacks",
	// 			name: "Boostpack Feed",
	// 			// component: () => import(/* webpackChunkName: "Feed" */ '../components/Boostpack/BoostpackFeed.vue'),
	// 			component: BoostpackFeed,
	// 			meta: {
	// 				title: "Boostpack | Feed",
	// 				metaTags: [
	// 					{
	// 						name: 'description',
	// 						content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
	// 					},
	// 					{
	// 						property: 'og:description',
	// 						content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
	// 					},
	// 					{
	// 						property: 'description',
	// 						content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
	// 					},
	// 					{
	// 						name: 'keywords',
	// 						content: 'motivation boost, motivational images, boost my hobby or habit, quotes from different categories, quotes for myself, subscribe daily quotes, quotes for my social media, find quote images, image feed, quote feed,'
	// 					},
	// 				],
	// 			},
	// 		}
	// 	],
	// },
	// TODO INSERT META TAGS (Language and for shared Boostpack)
	{
		path: '/detail/:id',
		name: 'Boostpack Detail',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "details" */ '../components/Card/Boostpack/DefaultBoostpackDetail.vue'),
		meta: {
			title: "Boostpack Detail",
			metaTags: [
				{
					name: 'description',
					content: 'Watch Motivational Images of :id Boostpack.'
				},
				{
					property: 'og:description',
					content: 'Watch Motivational Images of :id Boostpack.'
				},
				{
					property: 'description',
					content: 'Watch Motivational Images of :id Boostpack.'
				},
				{
					name: 'keywords',
					content: 'infos about quote authors, boostpack details, boostpack category details,'
				},
			],
		}
	},
	{
		path: '/categories/:category?',
		name: 'Categories',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "CategoryFeedDetail" */ '../components/Boostpack/BoostpackCategoryFeed.vue'),
		meta: {
			title: "Boostpack | Category Feed",
			metaTags: [
				{
					name: 'description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					property: 'og:description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					property: 'description',
					content: 'Search thousand of quotes that suitable for your Interest, easy and fast subscription for your daily Boost, visit and find yours.'
				},
				{
					name: 'keywords',
					content: 'motivation boost, boost my hobby or habit, quotes from different categories, quotes for myself, subscribe daily quotes, quotes for my social media, find quote images, image feed, quote feed,'
				},
			],
		}
	},
	// {
	// 	path: '/detail/:slug',
	// 	name: 'Boostpack Detail',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "details" */ '../components/Card/Boostpack/DefaultBoostpackDetail.vue'),
	// 	meta: {
	// 		title: "Boostpack Detail",
	// 		metaTags: [
	// 			{
	// 				name: 'description',
	// 				content: 'The home page of our example app.'
	// 			},
	// 			{
	// 				property: 'og:description',
	// 				content: 'The home page of our example app.'
	// 			}
	// 		],
	// 	}
	// },
	{
		path: '/pricing',
		name: 'Pricing',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "details" */ '../components/Payment/PricingList.vue'),
		meta: {
			title: "Pricing",
			metaTags: [
				{
					name: 'description',
					content: 'See our Plans for your Business.'
				},
				{
					property: 'og:description',
					content: 'See our Plans for your Business.'
				},
				{
					property: 'description',
					content: 'See our Plans for your Business.'
				},
				{
					name: 'keywords',
					content: 'motivational image, pricing boostpack, generate quote pictures, boostpack own content, social media content with logo, what are the benefits from boostpack business, can i insert my own logo on quote pictures, quote images for my business, social media content for my business, easy content, generate content for social media,'
				},
			],
		}
	},
	{
		path: '/boostpack/unsubscribe/:hash',
		name: 'Boostpack Unsubscribe',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "details" */ '../components/Boostpack/Unsubscribe/BoostpackUnsubscribe.vue'),
		meta: {
			title: "Boostpack Unsubscribe",
			metaTags: [],
		}
	},
	{
		path: '/404',
		name: 'PageNotExist',
		component: () => import(/* webpackChunkName: "details" */ '../components/Error/NotFoundComponent.vue'),
	},
	{
		path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
		redirect: '/404',
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

// router.beforeEach((to, from, next) => {
// 	let title = `${to.meta.title}`;
// 	if (title === "undefined") {
// 		title = "boostpack";
// 	}
// 	document.title = title;
//
// 	// Find the nearest route element with meta tags.
// 	//eslint-disable-next-line
// 	const nearestWithMeta: any = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
// 	// Remove any stale meta tags from the document using the key attribute we set below.
// 	document.querySelectorAll('[data-vue-router-controlled]').forEach(e => e.remove());
//
// 	// Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => function (){
// 	// 	console.log(el)
// 	// 	if (el && typeof el === 'object') {
// 	// 		if (el.parentNode) {
// 	// 			el.parentNode.removeChild(el)
// 	// 		}
// 	// 	}
// 	// });
//
// 	// Skip rendering meta tags if there are none.
// 	if(!nearestWithMeta) return next();
//
// 	// Turn the meta tag definitions into actual elements in the head.
// 	nearestWithMeta.meta.metaTags.map(tagDef => {
// 		const tag = document.createElement('meta');
//
// 		Object.keys(tagDef).forEach(key => {
// 			tag.setAttribute(key, tagDef[key]);
// 		});
//
// 		// We use this to track which meta tags we create so we don't interfere with other ones.
// 		tag.setAttribute('data-vue-router-controlled', '');
//
// 		return tag;
// 	})
// 	// Add the meta tags to the document head.
// 	.forEach(tag => document.head.appendChild(tag));
//
// 	window.scrollTo(0,0);
// 	next();
// });

router.beforeEach((to, from, next) => {
	let title = `${to.meta.title}`;
	if (title === "undefined") {
		title = "boostpack";
	}
	document.title = title;

	if (!to.meta.middleware || !(to.meta.middleware instanceof Array) || to.meta.middleware.length < 1) {
		return next();
	}

	const context = {
		to,
		from,
		next,
		store
	}

	window.scrollTo(0,0);
	return middlewarePipeline(context, to.meta.middleware, 0)();
})

export default router
