import {
	DOT_VERTICAL_SVG,
	LOGOUT_SVG,
	MENU_CLOSE_SVG,
	MENU_OPEN_SVG,
	QUESTION_SVG,
	SETTINGS_SVG,
	SHARE_SVG,
	SMILEY_SVG,
	USER_SVG,
	DROPDOWN_SVG,
	RETURN_SVG,
	EDIT_SVG,
	FACEBOOK_SVG,
	INSTAGRAM_SVG,
	YOUTUBE_SVG,
	TWITTER_SVG,
	RATING_SVG,
	RATINGFULL_SVG,
	COPY_SVG, SUBSCRIBE_SVG, NOTIFICATION_SVG, HOME_SVG,
	IMAGE_SVG,
	TEXTPOSITION_SVG,
	TEXT_SVG,
	TEXTCOLOUR_SVG,
	BACKGROUNDCOLOUR_SVG,
	TEXTFONT_SVG,


} from "@/composable/icon/icon.datalist";

export const USER_ICON = "user_icon";
export const LOGOUT_ICON = "logout_icon";
export const SHARE_ICON = "share_icon";
export const SUBSCRIBE_ICON = "subscribe_icon";
export const NOTIFICATION_ICON = "notification_icon";
export const SETTINGS_ICON = "settings_icon";
export const QUESTION_ICON = "question_icon";
export const DOT_VERTICAL_ICON = "dot_vertical_icon";
export const SMILEY_ICON = "smiley_icon";
export const MENU_OPEN_ICON = "menu_open_icon";
export const MENU_CLOSE_ICON = "menu_close_icon";
export const FACEBOOK_ICON = "facebook_icon";
export const TWITTER_ICON = "twitter_icon";
export const INSTAGRAM_ICON = "instagram_icon";
export const YOUTUBE_ICON = "youtube_icon";
export const DROPDOWN_ICON = "dropdown_icon";
export const RETURN_ICON = "return_icon";
export const EDIT_ICON = "edit_icon";
export const RATING_ICON = "rating_icon";
export const RATINGFULL_ICON = "ratingfull_icon";
export const COPY_ICON = "copy_icon";
export const HOME_ICON = "home_icon";
export const TEXT_ICON = "text_icon";
export const TEXTPOSITION_ICON = "textposition_icon";
export const TEXTCOLOUR_ICON = "textcolour_icon";
export const TEXTFONT_ICON = "textfont_icon";
export const IMAGE_ICON = "image_icon";
export const BACKGROUNDCOLOUR_ICON = "backgroundcolour_icon";


function buildIcon(svg: string, iconHeight = "h-8", iconWidth = "w-8", iconColor = "text-black"): string {
	return "<svg " + iconHeight + " " + iconWidth + " " + iconColor + ' viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">' + svg + '/></svg>';
}

function getIcon(icon: string, iconHeight = "h-8", iconWidth = "w-8", iconColor = "text-black") {
	switch (icon) {
		case USER_ICON:
			return buildIcon(USER_SVG, iconHeight, iconWidth, iconColor);
		case LOGOUT_ICON:
			return buildIcon(LOGOUT_SVG, iconHeight, iconWidth, iconColor);
		case SHARE_ICON:
			return buildIcon(SHARE_SVG, iconHeight, iconWidth, iconColor);
		case SUBSCRIBE_ICON:
			return buildIcon(SUBSCRIBE_SVG, iconHeight, iconWidth, iconColor);
		case NOTIFICATION_ICON:
			return buildIcon(NOTIFICATION_SVG, iconHeight, iconWidth, iconColor);
		case SETTINGS_ICON:
			return buildIcon(SETTINGS_SVG, iconHeight, iconWidth, iconColor);
		case QUESTION_ICON:
			return buildIcon(QUESTION_SVG, iconHeight, iconWidth, iconColor);
		case DOT_VERTICAL_ICON:
			return buildIcon(DOT_VERTICAL_SVG, iconHeight, iconWidth, iconColor);
		case SMILEY_ICON:
			return buildIcon(SMILEY_SVG, iconHeight, iconWidth, iconColor);
		case MENU_OPEN_ICON:
			return buildIcon(MENU_OPEN_SVG, iconHeight, iconWidth, iconColor);
		case MENU_CLOSE_ICON:
			return buildIcon(MENU_CLOSE_SVG, iconHeight, iconWidth, iconColor);
		case FACEBOOK_ICON:
			return buildIcon(FACEBOOK_SVG, iconHeight, iconWidth, iconColor);
		case TWITTER_ICON:
			return buildIcon(TWITTER_SVG, iconHeight, iconWidth, iconColor);
		case INSTAGRAM_ICON:
			return buildIcon(INSTAGRAM_SVG, iconHeight, iconWidth, iconColor);
		case YOUTUBE_ICON:
			return buildIcon(YOUTUBE_SVG, iconHeight, iconWidth, iconColor);
		case DROPDOWN_ICON:
			return buildIcon(DROPDOWN_SVG, iconHeight, iconWidth, iconColor);
		case RETURN_ICON:
			return buildIcon(RETURN_SVG, iconHeight, iconWidth, iconColor);
		case EDIT_ICON:
			return buildIcon(EDIT_SVG, iconHeight, iconWidth, iconColor);
		case RATING_ICON:
			return buildIcon(RATING_SVG, iconHeight, iconWidth, iconColor);
		case RATINGFULL_ICON:
			return buildIcon(RATINGFULL_SVG, iconHeight, iconWidth, iconColor);
		case COPY_ICON:
			return buildIcon(COPY_SVG, iconHeight, iconWidth, iconColor);
		case HOME_ICON:
			return buildIcon(HOME_SVG, iconHeight, iconWidth, iconColor);
		case IMAGE_ICON:
			return buildIcon(IMAGE_SVG, iconHeight, iconWidth, iconColor);
		case TEXTFONT_ICON:
			return buildIcon(TEXTFONT_SVG, iconHeight, iconWidth, iconColor);
		case TEXTPOSITION_ICON:
			return buildIcon(TEXTPOSITION_SVG, iconHeight, iconWidth, iconColor);
		case TEXTCOLOUR_ICON:
			return buildIcon(TEXTCOLOUR_SVG, iconHeight, iconWidth, iconColor);
		case BACKGROUNDCOLOUR_ICON:
			return buildIcon(BACKGROUNDCOLOUR_SVG, iconHeight, iconWidth, iconColor);
		case TEXT_ICON:
			return buildIcon(TEXT_SVG, iconHeight, iconWidth, iconColor);

		default:
			return "";
	}
}


export {getIcon};
