
import {defineComponent} from 'vue';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {ChevronDownIcon} from '@heroicons/vue/solid'
import useUserRepository from "@/composable/user/useUserRepository";
import router from "@/router";

export default defineComponent({
	name: "AvatarMenuItem",
	props: {},
	components: {
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
		ChevronDownIcon,
	},
	setup() {
		const {getFullName, getUserImage, getUserEmail, isAuthenticated, getUserInitials} = useUserRepository();

		function logout() {
			router.push('/logout');
		}

		return {getFullName, getUserImage, isAuthenticated, getUserInitials, logout};
	}
})
