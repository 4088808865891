import {BoostpackList} from "@/services/boostpack/boostpack_list.type";
import {Boostpack} from "@/services/boostpack/boostpack.type";

const boostpack = {
	namespaced: true,
	state: () => ({
		boostpackList: {} as BoostpackList,
		reloadData: false,
		searchValue: "",
	}),
	mutations: {
		// eslint-disable-next-line
		search(state: any, value: string) {
			state.searchValue = value;
		},
		// eslint-disable-next-line
		boostpackList(state: any, boostpackList: BoostpackList) {
			state.boostpackList = boostpackList;
		},
		// eslint-disable-next-line
		resetBoostpackList(state: any) {
			state.boostpackList = {} as BoostpackList;
		},
		// eslint-disable-next-line
		addBoostpackList(state: any,{boostpackList, clearBoostpackList = false}) {
			if (boostpackList) {
				if (clearBoostpackList || !state.boostpackList || state.reloadData) {
					state.boostpackList = new BoostpackList();
					state.reloadData = false;
				}
				for (const boostpack of boostpackList) {
					if (!state.boostpackList.find(o => o.id == boostpack.id )) {
						state.boostpackList.push(boostpack);
					}
				}
				state.boostpackList.paginationMetaData = boostpackList.paginationMetaData;
			}
		},
		// eslint-disable-next-line
		addBoostpackToList(state: any, boostpack: Boostpack) {
			if (boostpack) {
				if (!state.boostpackList.length) {
					state.boostpackList = new BoostpackList();
				}
				if (!state.boostpackList.find(o => o.id == boostpack.id )) {
					state.boostpackList.push(boostpack);
				}
			}
		},
		// eslint-disable-next-line
		removeBoostpackFromList(state: any, boostpackId: number) {
			if (boostpackId && state.boostpackList.length > 0) {
				const boostpackIndex = state.boostpackList.findIndex(function(o){
					return o.id === boostpackId;
				})
				if (boostpackIndex !== -1) state.boostpackList.splice(boostpackIndex, 1);
			}
		},

		// eslint-disable-next-line
		reloadData(state: any, value: boolean) {
			state.reloadData = value;
		},
	},
	actions: {},
	getters: {
		// eslint-disable-next-line
		getSearchValue: (state: any) => {
			return state.searchValue;
		},
		getBoostpacks: (state: any) => {
			return state.boostpackList;
		},
		// eslint-disable-next-line
		getBoostpackById: (state: any, getters) => (boostpackId: number) => {
			if (state.boostpackList.length > 0) {
				return getters.getBoostpacks.find(o => o.id == boostpackId);
			}
			return null;
		},
		// eslint-disable-next-line
		getBoostpackByReference: (state: any, getters) => (boostpackReference?: string) => {
			if (state.boostpackList.length > 0) {
				return getters.getBoostpacks.find(o => o.reference == boostpackReference);
			}
			return null;
		},
		// eslint-disable-next-line
		getReloadData: (state: any) => {
			return state.reloadData;
		},
	}
}

export default boostpack;