
import {defineComponent} from 'vue';
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: 'FooterLabel',
	components: {DefaultIcon},
	props: {
		itemMy: {
			type: String,
			default: "my-2"
		},
		itemText: {
			type: String,
			default: ""
		},
		itemIconPre: {
			type: String,
			default: ""
		},
		itemIconAfter: {
			type: String,
			default: ""
		},
		itemWidth: {
			type: String,
			default: "",
		},
		showItem: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		}
	},
	setup() {
		return {};
	}
})
