import {computed} from 'vue';
import {store} from "@/store";
import {AlertInterface} from "@/types/alert.interface";

export const enum AlertTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	SECONDARY = 'secondary',
}

export default function useAlerts() {
	const getErrors = computed(() => store.getters['alert/getErrors']);
	const getSuccesses = computed(() => store.getters['alert/getSuccesses']);
	const getWarnings = computed(() => store.getters['alert/getWarnings']);
	const getSecondaries = computed(() => store.getters['alert/getSecondaries']);
	const getAllAlerts = computed(() => store.getters['alert/getAllAlerts']);

	function addError(error: AlertInterface) {
		store.commit('alert/addErrorAlert', error)
	}

	function addSuccess(success: AlertInterface) {
		store.commit('alert/addSuccessAlert', success)
	}

	function addWarning(warning: AlertInterface) {
		store.commit('alert/addWarningAlert', warning)
	}

	function addSecondary(secondary: AlertInterface) {
		store.commit('alert/addSecondaryAlert', secondary)
	}

	function removeAlert(alertId: string) {
		store.commit('alert/removeAlert', alertId)
	}

	function removeAlertByType(type: string) {
		store.commit('alert/removeAlertByType', type)
	}

	function clearErrorAlert() {
		store.commit('alert/removeAlertByType', 'error')
	}

	return {
		getErrors,
		addError,
		getSuccesses,
		addSuccess,
		getWarnings,
		getSecondaries,
		getAllAlerts,
		addWarning,
		addSecondary,
		removeAlert,
		removeAlertByType,
		clearErrorAlert,
	}
}
