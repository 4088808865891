import ApiService from "../base/api.service";
import {BoostpackFactory} from "@/services/boostpack/boostpack_factory.type";
import {Boostpack} from "@/services/boostpack/boostpack.type";

const resource = '/rating';

class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const RatingService = {
	get() {
		try {
			//TODO pagination interface
			return ApiService.get(`${resource}`);
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},
	create(payload: any) {
		return ApiService.post(`${resource}`, payload);
	},
	update(payload: any, id: number) {
		return ApiService.put(`${resource}/${id}`, payload);
	},
	delete(id: number) {
		return ApiService.delete(`${resource}/${id}`)
	},
}

export { RatingService, ResponseError };