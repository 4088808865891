
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'DefaultCardLayout',
	components: {},
	props: {
    //sm:max-w-sm max-w-xs (old default: w-full)
    cardWidth: {
      type: String,
      default: "",
    },
    displayGrid: {
      type: String,
      default: "",
    },
    cardHeight: {
      type: String,
      default: "",
    },
    mobileCardWidth: {
      type: String,
      default: "",
      // default: "sm:w-full",
    },
    centerCard: {
      type: Boolean,
      default: true,
    },
    cardPadding: {
      type: String,
      default: 'px-6 pb-6',
    },
    overflowCard: {
      type: String,
      default: 'overflow-hidden',
    },
    cardBg: {
      type: String,
      default: 'bg-white dark:bg-gray-900',
    },
    cardMargin: {
      type: String,
      default: 'mt-6',
    },
    cardInnerMargin: {
      type: String,
      default: 'my-6',
    },
  },
	setup() {
		return {};
	}
})
