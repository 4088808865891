
import {defineComponent, onMounted, ref} from 'vue';
import FooterColumnLayout from "@/components/Nav/Footer/FooterColumnLayout.vue";
import FooterItem from "@/components/Nav/Footer/FooterItem.vue";
import FooterLabel from "@/components/Nav/Footer/FooterLabel.vue";
import FooterRedirect from "@/components/Nav/Footer/FooterRedirect.vue";
import LanguageSelector from "@/components/Locale/LanguageSelector.vue";

export default defineComponent({
	name: 'Footer',
	components: {LanguageSelector, FooterRedirect, FooterLabel, FooterItem, FooterColumnLayout},
	props: {},
	emits: [],
	setup() {
		const companyName = ref(process.env.VUE_APP_NAME);
		const copyRightText = ref('');

		function getCopyrightText() {
			const currentYear = new Date().getFullYear();
			copyRightText.value = "©" + " " + currentYear + " by " + process.env.VUE_APP_NAME;
		}

		function currentEnvironment() {
			return process.env.NODE_ENV;
		}

		onMounted(() => {
			getCopyrightText();
		})

		return {companyName, copyRightText, currentEnvironment}
	},
})
