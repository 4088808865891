
import {defineComponent, ref} from 'vue';
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: "RatingIcons",
	components: {DefaultIcon},
	props: {
		grade: {
			type: Number,
			default: 0,
		},
		maxStars: {
			type: Number,
			default: 5
		},
		hasCounter: {
			type: Boolean,
			default: true
		}
	},
	setup(props) {
		const stars = ref(props.grade);

		function rate(star: number) {
			if (star <= props.maxStars && star >= 0) {
				stars.value = stars.value === star ? star - 1 : star
			}
		}

		function getStars(): number {
			return stars.value;
		}

		return {stars, rate, getStars};
	}
})

