import ApiService from "../base/api.service";
import {BoostpackFactory} from "@/services/boostpack/boostpack_factory.type";
import {Boostpack} from "@/services/boostpack/boostpack.type";

const resource = '/boostpack';

/* eslint-disable */
class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

const BoostpackService = {
	get() {
		try {
			//TODO pagination interface
			return ApiService.get(`${resource}`);
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	getBoostpackFeed: async function (page: string|null = null) {
		try {
			let result = {};
			let url = resource;
			if (page) {
				url += '?page=' + page;
			}
			const response = await ApiService.get(url);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					result = boostpackFactory.createBoostpackListByResponse(responseData);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				// The request was made but no response was received
				// Error details are stored in error.reqeust
				console.log(error.request);
			} else {
				// Some other errors
				console.log('Error', error.message);
			}
		}
	},

	getBoostpackFeedWithParameter: async function (boostpackParams: Object = {}) {
		try {
			let result = {};
			const response = await ApiService.get(`${resource}`, { params: boostpackParams });
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					result = boostpackFactory.createBoostpackListByResponse(responseData);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	getBoostpackFeedBySearch: async function (search: string, page: string|null = null, perPage: number|null = null) {
		try {
			let result: any = {};
			const params = new URLSearchParams([['search', search]]);
			if (page) {
				params.append('page', page);
			}
			if (perPage) {
				params.append('perPage', String(perPage));
			}
			const response = await ApiService.get(`${resource}/search`, {params});
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					result = boostpackFactory.createBoostpackListWithMetaData(response.data);
					// result = boostpackFactory.createBoostpackListByResponse(responseData);
				}
			}
			return result;
		} catch (error) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	async getBoostpack(id: number):  Promise<Boostpack | null>
	{
		try {
			const response = await ApiService.get(`${resource}/${id}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					return boostpackFactory.createBoostpackByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	async getBoostpackItemFeed(limit = 100):  Promise<string[] | null>
	{
		try {
			const response = await ApiService.get(`${resource}/images`, { params: { limit: limit } });
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					return responseData;
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	async getBoostpackItemFeedWithAdditionalInfo(limit: string = "null", page: string|null = null, search: string|null = null):  Promise<[] | null>
	{
		try {
			const params = new URLSearchParams([['limit', limit]]);
			if (page) {
				params.append('page', page);
			}
			if (search) {
				params.append('search', search);
			}
			const response = await ApiService.get(`${resource}/images/info`, { params });
			if (response) {
				const responseData = response.data;
				if (responseData) {
					return responseData;
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	async getBoostpackByReference(reference: string):  Promise<Boostpack | null>
	{
		try {
			const response = await ApiService.get(`${resource}/reference/${reference}`);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const boostpackFactory = new BoostpackFactory();
					return boostpackFactory.createBoostpackByResponse(responseData);
				}
			}
			return null;
		} catch (error) {
			throw new ResponseError(
				error.status,
				error.message
			);
		}
	},

	create(payload: any) {
		return ApiService.post(`${resource}`, payload);
	},
	update(payload: any, id: number) {
		return ApiService.put(`${resource}/${id}`, payload);
	},
	delete(id: number) {
		return ApiService.delete(`${resource}/${id}`)
	},
	fetchBoostPackRepositoryByCategories(category: Record<string, any>) {
		return ApiService.post(`${resource}`+"/fetchByCategories/", category);
	},
	fetchBoostPackRepositoryByCategory(category: string) {
		return ApiService.get(`${resource}`+"/fetchByCategory/"+`${category}`);
	},
	subscribe(payload: any) {
		return ApiService.post(`${resource}/subscribe`, payload);
	},
	checkUnsubscribeHash(payload: any) {
		return ApiService.post(`${resource}/unsubscribe/check`, payload);
	},
	unsubscribe(payload: any) {
		return ApiService.post(`${resource}/unsubscribe`, payload);
	},
	likeBoostpackItem(payload: any) {
		return ApiService.post(`${resource}/item/like`, payload);
	},
}

export { BoostpackService, ResponseError };