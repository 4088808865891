import {BoostpackList} from "@/services/boostpack/boostpack_list.type";
import {Boostpack} from "@/services/boostpack/boostpack.type";
import {BoostpackItemList} from "@/services/boostpack/boostpack_item_list.type";
import {MediaItemList} from "@/services/boostpack/media/media_item_list.type";
import {MediaItem} from "@/services/boostpack/media/media_item.type";
import {BoostpackItem} from "@/services/boostpack/boostpack_item.type";
import {Category} from "@/services/boostpack/category/category.type";
import {User} from "@/services/boostpack/user/user.type";
import {CategoryList} from "@/services/boostpack/category/category_list.type";

/**
 *
 */
export class BoostpackFactory {

	/* eslint-disable */

	/**
	 *
	 * @param boostpackData
	 */
	createBoostpackListByResponse(boostpackData: Record<string, any>){
		const boostpackList = new BoostpackList();
		let boostpack;

		for (const [key, item] of Object.entries(boostpackData)) {
			const id = item.id;
			const reference = item.reference;
			const userId = item.user_id;
			const user = this.createUserByData(item.user);
			const active = item.active;
			const state = item.state;
			const boostpackItems = this.createBoostpackItemListByBoostpackItemData(item.boostpack_items);
			const category = this.createCategoryByData(item.category);
			const createdAt = item.created_at;
			const updatedAt = item.updated_at;
			const data = item.data;
			const title = item.title;
			const description = item.description;
			const shortDescription = item.short_description;
			const netAmount = item.net_amount;
			const taxAmount = item.tax_amount;
			const totalAmount = item.total_amount;
			const taxCode = item.tax_code;
			boostpack = new Boostpack(active, boostpackItems, category, createdAt, data, description, id, netAmount, shortDescription, state, taxAmount, taxCode, title, totalAmount, updatedAt, userId, user, null, reference);
			boostpackList.push(boostpack);
		}

		return boostpackList;
	}

	createBoostpackListWithMetaData(boostpackResponse: Record<string, any>) {
		const boostpackData = boostpackResponse.data;
		const metaData = boostpackResponse.meta;
		let boostpackList = this.createBoostpackListByResponse(boostpackData)
		boostpackList.enhanceMetaData(metaData);

		return boostpackList;
	}

	/**
	 *
	 * @param item
	 */
	createBoostpackByResponse(item: Record<string, any>): Boostpack{
		let boostpack;

		const id = item.id;
		const reference = item.reference;
		const userId = item.user_id;
		const user = this.createUserByData(item.user);
		const active = item.active;
		const state = item.state;
		const boostpackItems = this.createBoostpackItemListByBoostpackItemData(item.boostpack_items);
		const category = this.createCategoryByData(item.category);
		const createdAt = item.created_at;
		const updatedAt = item.updated_at;
		const data = item.data;
		const title = item.title;
		const description = item.description;
		const shortDescription = item.short_description;
		const netAmount = item.net_amount;
		const taxAmount = item.tax_amount;
		const totalAmount = item.total_amount;
		const taxCode = item.tax_code;
		boostpack = new Boostpack(active,boostpackItems, category, createdAt,data,description, id, netAmount, shortDescription, state, taxAmount, taxCode, title, totalAmount, updatedAt, userId, user, null, reference);

		return boostpack;
	}

	/**
	 *
	 * @param boostpackItemData
	 */
	createBoostpackItemListByBoostpackItemData(boostpackItemData: Record<string, any>) {
		const boostpackItemList = new BoostpackItemList();

		for (const [key, item] of Object.entries(boostpackItemData)) {
			const id = item.id;
			const reference = item.reference;
			const boostpackId = item.boostpack_id ? item.boostpack_Id : item.boostpackId ? item.boostpackId : "";
			const data = item.data;
			const mediaItems = this.createMediaItemsByData(item.mediaItems);
			const order = item.order;
			const state = item.state;
			const type = item.type;
			const createdAt = item.created_at ? item.created_at : item.createdAt ? item.createdAt : "";
			const updatedAt = item.updated_at ? item.updated_at : item.updatedAt ? item.updatedAt : "";
			const boostpackItem = new BoostpackItem(boostpackId, createdAt,data,id,mediaItems,order,state,type,updatedAt, reference);
			boostpackItemList.push(boostpackItem);
		}

		return boostpackItemList;
	}

	/**
	 *
	 * @param mediaItemData
	 */
	createMediaItemsByData(mediaItemData: Record<string, any>): MediaItemList {
		const mediaItemList = new MediaItemList();

		for (const [key, item] of Object.entries(mediaItemData)) {
			const publicFullUrl = item.public_full_url ? item.public_full_url : item.publicFullUrl ? item.publicFullUrl : "";
			const publicUrl = item.public_url ? item.public_url : item.publicUrl ? item.publicUrl : "";
			const thumbMedium = item.thumb_medium ? item.thumb_medium : item.thumbMedium ? item.thumbMedium : publicUrl;
			const thumbSmall = item.thumb_small ? item.thumb_small : item.thumbSmall ? item.thumbSmall : publicUrl;
			const mediaItem = new MediaItem(publicFullUrl, publicUrl, thumbMedium, thumbSmall);
			mediaItemList.push(mediaItem);
		}

		return mediaItemList;
	}

	/**
	 *
	 * @param categoryData
	 */
	createCategoryListByResponse(categoryData: Record<string, any>){
		const categoryList = new CategoryList();

		for (const [key, item] of Object.entries(categoryData)) {
			categoryList.push(this.createCategoryByData(item));
		}

		return categoryList;
	}

	/**
	 *
	 * @param categoryData
	 */
	createCategoryByData(categoryData: Record<string, any>) {
		let category;
		for (const [key, item] of Object.entries(categoryData)) {
			const id = categoryData.id;
			const categoryDescription = categoryData.category_description;
			const categoryKey = categoryData.category_key;
			const categoryName = categoryData.category_name;
			const languageCode = categoryData.language_code;
			const createdAt = categoryData.created_at;
			const updatedAt = categoryData.updatedAt;
			category = new Category(categoryDescription, categoryKey, categoryName, createdAt, id, languageCode, updatedAt);
		}

		return category;
	}

	/**
	 *
	 * @param userData
	 */
	createUserByData(userData: Record<string, any>) {
		let user;
		for (const [key, item] of Object.entries(userData)) {
			const id = userData.id;
			const type = userData.type;
			const state = userData.state;
			const name = userData.name;
			const email = userData.email;
			const profilePhotoPath = userData.profile_photo_path;
			const profilePhotoUrl = userData.profile_photo_url;
			const emailVerifiedAt = userData.email_verified_at;
			const currentTeamId = userData.current_team_id;
			const createdAt = userData.created_at;
			const updatedAt = userData.updatedAt;
			user = new User(createdAt, currentTeamId, email, emailVerifiedAt, id, name, profilePhotoPath, profilePhotoUrl, state, type, updatedAt);
		}

		return user;
	}

	/**
	 *
	 * @param boostpackItemList
	 * @param useSmallThumb
	 */
	createRandomTitleImageByBoostpackItemList(boostpackItemList: []|BoostpackItemList, useSmallThumb = true) {
		let result = "";
		if (boostpackItemList instanceof BoostpackItemList){
			result = boostpackItemList.getRandomImage();
		} else {
			try {
				const randomBoostpackItem = this.createBoostpackItemListByBoostpackItemData(boostpackItemList);
				result = randomBoostpackItem.getRandomImage(useSmallThumb);
			}
			catch (e) {
				console.log(e);
			}
		}
		return result;
	}
}