import {BoostpackItemInterface} from "@/services/boostpack/boostpack_item.interface";
import {MediaItemList} from "@/services/boostpack/media/media_item_list.type";

export class BoostpackItem implements BoostpackItemInterface {
	boostpackId: number;
	createdAt: Date;
	data: any;
	id: number;
	reference?;
	mediaItems: MediaItemList;
	order: number;
	state: string;
	type: string;
	updatedAt: Date;


	constructor(boostpackId: number, createdAt: Date, data: any, id: number, mediaItems: MediaItemList, order: number, state: string, type: string, updatedAt: Date, reference?: string | null) {
		this.boostpackId = boostpackId;
		this.createdAt = createdAt;
		this.data = data;
		this.id = id;
		this.reference = reference;
		this.mediaItems = mediaItems;
		this.order = order;
		this.state = state;
		this.type = type;
		this.updatedAt = updatedAt;
	}
}