import {UserInterface} from "@/services/boostpack/user/user.interface";

export class User implements UserInterface {
	createdAt: Date;
	currentTeamId: number;
	email: string;
	emailVerifiedAt: Date;
	id: number;
	name: string;
	profilePhotoPath: string;
	profilePhotoUrl: string;
	state: string;
	type: string;
	updatedAt: Date;

	/**
	 *
	 * @param createdAt
	 * @param currentTeamId
	 * @param email
	 * @param emailVerifiedAt
	 * @param id
	 * @param name
	 * @param profilePhotoPath
	 * @param profilePhotoUrl
	 * @param state
	 * @param type
	 * @param updatedAt
	 */
	constructor(createdAt: Date, currentTeamId: number, email: string, emailVerifiedAt: Date, id: number, name: string, profilePhotoPath: string, profilePhotoUrl: string, state: string, type: string, updatedAt: Date) {
		this.createdAt = createdAt;
		this.currentTeamId = currentTeamId;
		this.email = email;
		this.emailVerifiedAt = emailVerifiedAt;
		this.id = id;
		this.name = name;
		this.profilePhotoPath = profilePhotoPath;
		this.profilePhotoUrl = profilePhotoUrl;
		this.state = state;
		this.type = type;
		this.updatedAt = updatedAt;
	}
}