import {MediaItemInterface} from "@/services/boostpack/media/media_item.interface";

export class MediaItem implements MediaItemInterface {
	publicFullUrl: string;
	publicUrl: string;
	thumbMedium: string;
	thumbSmall: string;

	constructor(publicFullUrl: string, publicUrl: string, thumbMedium: string, thumbSmall: string) {
		this.publicFullUrl = publicFullUrl;
		this.publicUrl = publicUrl;
		this.thumbMedium = thumbMedium;
		this.thumbSmall = thumbSmall;
	}
}