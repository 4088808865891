
import {computed, defineComponent, onMounted,ref} from 'vue';
import {useStore} from "@/store";

export default defineComponent({
	name: 'Search',
	props: {
		searchPlaceholder: {
			type: String,
			default: 'Search',
		},
		enableSaveSearchValueInStorage: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['search', 'redirectSearch'],
	setup(props, {emit}) {
		const store = useStore()

		// const searchValue = ref("");
		const searchValue = computed(() => store.getters['boostpack/getSearchValue']);
		const localSearchValue = ref('');
		const modelValue = ref('');

		function initModelValue() {
			if (props.enableSaveSearchValueInStorage) {
				modelValue.value = searchValue.value;
			} else {
				modelValue.value = localSearchValue.value;
			}
		}

		function setSearchValue(event) {
			if (props.enableSaveSearchValueInStorage) {
				store.commit('boostpack/search', event.target.value);
			}
			modelValue.value = event.target.value;
			localSearchValue.value = event.target.value;
		}

		function resetSearch() {
			if (props.enableSaveSearchValueInStorage) {
				store.commit('boostpack/search', "");
			}
			modelValue.value = "";
			localSearchValue.value = "";
			emit('search', searchValue);
		}

		function searchRouting() {
			emit('redirectSearch', searchValue);
		}

		function search() {
			emit('search', searchValue);
		}

		onMounted(() => {
			initModelValue();
		})

		return {
			searchValue,
			localSearchValue,
			searchRouting,
			setSearchValue,
			search,
			resetSearch,
			modelValue
		}
	},
	methods: {}
})
