
import {defineComponent} from 'vue';
import useCustomStore from "@/composable/custom/useCustomStore";
import { useI18n } from "vue-i18n";

export default defineComponent({
	name: 'LanguageSelector',
	components: {},
	props: {},
	setup() {
		const {setLanguageLocale} = useCustomStore();
		const arrLanguageMap = {"de": "Deutsch", "en": "English"};
		const i18n = useI18n();

		function changeLanguage() {
			setLanguageLocale(i18n.locale.value);
		}

		return {arrLanguageMap, changeLanguage};
	}
})
