<template>
	<div id="app" class="bg-gray-100 dark:bg-black">
		<Header></Header>
		<AlertComponent></AlertComponent>
		<!--    <BtnRateSticky></BtnRateSticky>-->
<!--		<router-view/>-->
		<router-view :key="route.path" v-slot="{Component}">
			<transition name="slide" mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
<!--		<ad-blocker-dialog></ad-blocker-dialog>-->
<!--		<cookies-dialog></cookies-dialog>-->
		<Footer></Footer>
	</div>
</template>

<style>

</style>
<script>
import {onMounted} from 'vue';
import Header from "@/components/Nav/Header/Header";
import Footer from "@/components/Nav/Footer/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CookiesDialog from "@/components/Modal/CookiesDialog";
import AdBlockerDialog from "@/components/Modal/AdBlockerDialog";
import AlertComponent from "@/components/Alert/AlertComponent";
import useCustomStore from "@/composable/custom/useCustomStore";
import { useI18n } from "vue-i18n";
import {useRoute} from "vue-router";

AOS.init();
AOS.refreshHard();
export default {
	components: {AlertComponent, Footer, Header},
	setup() {
		const {getLanguageLocale, setLanguageLocale, getDefaultBrowserLanguageCode} = useCustomStore();
		const i18n = useI18n();
		const route = useRoute();

		onMounted(() => {
			if (getLanguageLocale.value.length === 0) {
				setLanguageLocale(getDefaultBrowserLanguageCode());
			}
			i18n.locale.value = getLanguageLocale.value;
		})


		return {
			route
		}
	}
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.scale-enter-active,
.scale-leave-active {
    transition: all 0.5s ease;
}
.scale-enter-from,
.scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
}


/*.slide-enter-active,*/
/*.slide-leave-active {*/
/*    transition: all 0.5s ease;*/
/*}*/
/*.slide-enter-from,*/
/*.slide-leave-to {*/
/*    opacity: 0;*/
/*    transform: scale(0.9);*/
/*}*/

</style>
