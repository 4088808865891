
import {defineComponent, onMounted, ref} from 'vue';
import {getIcon} from "@/composable/icon/icon.type";

export default defineComponent({
	name: 'DefaultIcon',
	components: {},
	props: {
		icon: {
			type: String,
			default: "E-Mail"
		},
		iconHeight: {
      type: String,
      default: "h-7",
    },
		iconWidth: {
      type: String,
      default: "w-7",
    },
		iconColor: {
      type: String,
      default: "",
    },
		cssClass: {
      type: String,
      default: "p-0.5",
    }
	},
	setup(props) {

		const iconSrc = ref("");

		function loadIcon() {
			iconSrc.value = getIcon(props.icon, props.iconHeight, props.iconWidth, props.iconColor);
		}

		onMounted(() => {
			loadIcon();
		})

		return {
			iconSrc
		};
	}
})
