
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router'
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";
import ConfirmDialog from "@/components/Modal/ConfirmDialog.vue";
import DeleteConfirmDialog from "@/components/Modal/DeleteConfirmDialog.vue";

export default defineComponent({
	name: 'HeaderItem',
	components: {DeleteConfirmDialog, ConfirmDialog, DefaultIcon},
	props: {
		itemText: {
			type: String,
			default: ""
		},
		itemIconPre: {
			type: String,
			default: ""
		},
		itemIconAfter: {
			type: String,
			default: "",
		},
		itemColorOff: {
			type: String,
			default: "text-gray-50 dark:text-gray-600",
		},
		itemColorOn: {
			type: String,
			default: "",
		},
		itemWidth: {
			type: String,
			default: "",
		},
		itemHeight: {
			type: String,
			default: "",
		},
		link: {
			type: String,
			default: "",
		},
		routeName: {
			type: String,
			default: "",
		},
		activeRouteList: {
			type: Array,
			default: () => [],
		},
		showItem: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		},
		enableConfirmDialog: {
			type: Boolean,
			default: false,
		},
		enableDeleteDialog: {
			type: Boolean,
			default: false,
		},
		confirmTitle: {
			type: String,
			default: ""
		},
		confirmDescription: {
			type: String,
			default: ""
		},
		deleteConfirmTitle: {
			type: String,
			default: ""
		},
		deleteConfirmDescription: {
			type: String,
			default: ""
		},
	},
	emits: ['confirmDialog', 'deleteConfirmDialog'],
	setup(props, {emit}) {
		const route = useRoute();
		const redirectLink = ref(props.link);
		const activeRoute = ref();
		const confirmRef = ref<InstanceType<typeof ConfirmDialog>>();
		const deleteConfirmRef = ref<InstanceType<typeof DeleteConfirmDialog>>();

		//TODO allow multiple links
		function isRouteActive(): boolean {
			if (props.routeName.length > 0 && !props.activeRouteList?.length) {
				return route.name === props.routeName;
			} else if (props.activeRouteList && props.activeRouteList.length > 0) {
				return props.activeRouteList.some((routePath) => routePath == route.path);
			} else {
				return route.path === props.link;
			}
		}

		const toggleConfirmDialog = () => {
			confirmRef.value?.toggleDialog();
		}

		const toggleDeleteConfirmDialog = () => {
			deleteConfirmRef.value?.toggleDialog();
		}

		const confirmDialog = () => {
			emit('confirmDialog');
			toggleConfirmDialog;
		};

		const deleteConfirmDialog = () => {
			emit('deleteConfirmDialog');
			toggleDeleteConfirmDialog;
		};

		onMounted(() => {
			activeRoute.value = isRouteActive();
		})

		// eslint-disable-next-line
		watch(route, (route, prevRoute) => {
			activeRoute.value = isRouteActive();
		})

		return {
			confirmRef,
			deleteConfirmRef,
			redirectLink,
			activeRoute,
			toggleConfirmDialog,
			toggleDeleteConfirmDialog,
			confirmDialog,
			deleteConfirmDialog
		};
	}
})
