
import {defineComponent, ref} from 'vue';
import router from "@/router";

export default defineComponent({
	name: 'DefaultRedirectButton',
	components: {},
	props: {
		buttonType: {
			type: String,
			default: "submit"
		},
		buttonText: {
			type: String,
			default: "E-Mail"
		},
		buttonWidth: {
			type: String,
			default: "w-full",
		},
		centerButton: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		},
		flexButton: {
			type: String,
			default: "flex",
		},
		link: {
			type: String,
			default: "",
		},
		buttonColor: {
			type: String,
			default: "",
		},
	},
	emits: ['clickButton'],
	setup(props) {
		const redirectLink = ref(props.link);

		function redirectToLink() {
			if (redirectLink.value) {
				router.push(redirectLink.value);
			}
		}

		return {
			redirectToLink
		};
	}
})
