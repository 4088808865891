import {CategoryInterface} from "@/services/boostpack/category/category.interface";

export class Category implements CategoryInterface {
	categoryDescription: string;
	categoryKey: string;
	categoryName: string;
	createdAt: Date;
	id: number;
	languageCode: string;
	updatedAt: Date;

	/**
	 *
	 * @param categoryDescription
	 * @param categoryKey
	 * @param categoryName
	 * @param createdAt
	 * @param id
	 * @param languageCode
	 * @param updatedAt
	 */
	constructor(categoryDescription: string, categoryKey: string, categoryName: string, createdAt: Date, id: number, languageCode: string, updatedAt: Date) {
		this.categoryDescription = categoryDescription;
		this.categoryKey = categoryKey;
		this.categoryName = categoryName;
		this.createdAt = createdAt;
		this.id = id;
		this.languageCode = languageCode;
		this.updatedAt = updatedAt;
	}

}