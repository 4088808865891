
export default {
	name: "BtnClose",
	props: {
		cssClass: {
			type: String,
			default: "",
		},
		btnPosition: {
			type: String,
			default: "mr-14 -mt-1",
		},
	},
	emits: ['close'],
	setup(props, {emit}) {
		const closeClick = () => {
			emit('close');
		};

		return {closeClick};
	}
};
