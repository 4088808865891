
import {defineComponent, onMounted, ref, watch} from 'vue';
import {nanoid} from 'nanoid';
import useAlerts, {AlertTypes} from "@/composable/alert/useAlerts";
import {CheckCircleIcon, ExclamationIcon, XCircleIcon, XIcon} from '@heroicons/vue/solid'

export default defineComponent({
	name: 'BaseAlertComponent',
	components: {XCircleIcon, CheckCircleIcon, XIcon, ExclamationIcon},
	props: {
		id: {
			type: String,
			default: null,
		},
		alertPadding: {
			type: String,
			// default: "pt-4",
			default: "pt-4 fixed w-full p-4",
		},
		alertPosition: {
			type: String,
			default: "p-4 ",
		},
		alertType: {
			type: String,
			default: "error",
		},
		title: {
			type: String,
			default: "",
		},
		message: {
			type: String,
			default: "",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		showIcon: {
			type: Boolean,
			default: true,
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showAsList: {
			type: Boolean,
			default: false,
		},
		cssClass: {
			type: String,
			default: "",
		},
		useAlertListMessageFromStorage: {
			type: Boolean,
			default: false,
		},
		showAlert: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['closeAlert'],
	setup(props, {emit}) {
		const {getAllAlerts, removeAlertByType} = useAlerts();
		const alertId = ref(props.id);
		const alertMessageList = ref();
		const alertTitle = ref(props.title);
		const alertMessage = ref(props.message);
		const alertUseAlertListMessageFromStorage = ref(props.useAlertListMessageFromStorage);
		const hasErrorMessagesToShow = ref(false);
		const test = ref();

		function hasAlertMessageList() {
			return getAllAlerts.value !== undefined &&  getAllAlerts.value[props.alertType] && getAllAlerts.value[props.alertType].length > 0;
		}

		// function updateErrorMessageToShowCheck() {
		// 	hasErrorMessagesToShow.value = ((hasAlertMessageList() || alertTitle.value.length > 0 || alertMessage.value.length > 0) && props.showAlert) === true;
		// }

		function isError() {
			return props.alertType === "error";
		}

		function isSuccess() {
			return props.alertType === "success";
		}

		function isWarning() {
			return props.alertType === "warning";
		}

		function isSecondary() {
			return props.alertType === "secondary";
		}

		const closeAlert = (selectedId) => {
			//TODO check if delete element or just disable it
			if (selectedId) {
				const elementToRemove = document.getElementById(selectedId);
				if (elementToRemove) {
					alertTitle.value = "";
					alertMessage.value = "";
					if (alertUseAlertListMessageFromStorage.value) {
						removeAlertByType(props.alertType);
					}
				}
			} else {
				removeAlertByType(props.alertType);
			}
			hasErrorMessagesToShow.value = false;
			emit('closeAlert');
		};

		onMounted(() => {
			// if (alertId.value === null || !alertId.value) {
			// 	alertId.value = nanoid();
			// }
			// if (!hasAlertMessageList() && alertUseAlertListMessageFromStorage.value) {
			// 	loadAlertMessageList();
			// }
		})
		//
		// watch(getSuccesses.value, (newValue, oldValue) => {
		// 	if (isSuccess()) {
		// 		loadAlertMessageList();
		// 	}
		// });
		//
		// watch(getErrors.value, (newValue, oldValue) => {
		// 	if (isError()) {
		// 		loadAlertMessageList();
		// 	}
		// });
		//
		//
		// watch(getWarnings.value, (newValue, oldValue) => {
		// 	if (isWarning()) {
		// 		loadAlertMessageList();
		// 	}
		// });
		//
		// watch(getSecondaries.value, (newValue, oldValue) => {
		// 	if (isSecondary()) {
		// 		loadAlertMessageList();
		// 	}
		// });

		// watch(() => props.title, (newValue) => {console.log("OH"); alertTitle.value = newValue; updateErrorMessageToShowCheck()});
		// watch(() => props.message, (newValue) => {alertMessage.value = newValue; updateErrorMessageToShowCheck()});

		return {
			getAllAlerts,
			alertMessageList,
			hasAlertMessageList,
			alertId,
			alertTitle,
			alertMessage,
			closeAlert,
			isError,
			isSuccess,
			isWarning,
			isSecondary,
			hasErrorMessagesToShow,
			test,
		};
	}
})
