import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: [' float-right'] + ' ' + $props.btnPosition
  }, [
    _createVNode("div", {
      class: ['absolute z-10 cursor-pointer float-right w-9 h-9 rounded-3xl ' +
     'text-center justify-self-center ' +
      'bg-gray-200 dark:bg-gray-700 text-gray-500 text-2xl', $props.cssClass],
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.closeClick && $setup.closeClick(...args))),
      title: "close"
    }, "x ", 2)
  ], 2))
}