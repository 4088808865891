import {Category} from "@/services/boostpack/category/category.type";

export class CategoryList extends Array<Category> {

	constructor(items: Array<Category> = []) {
		super();
		this.push(...items);
		Object.setPrototypeOf(this, CategoryList.prototype);
	}

	add(items: Category) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getCategory(index: number): Category {
		return this[index];
	}

	getCategoryById(categoryId: number): Category | undefined {
		return this.find( ({ id }) => id === categoryId);
	}
}