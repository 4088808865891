import {BoostpackItem} from "@/services/boostpack/boostpack_item.type";

/**
 *
 */
export class BoostpackItemList extends Array<BoostpackItem> {

	constructor(items: BoostpackItem[] = []) {
		super();
		this.push(...items);
	}

	add(items: BoostpackItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): BoostpackItem {
		return this[index];
	}

	getRandomImage(useSmallThumb = true): string
	{
		let result = "/img/test/coffee-5963334_1920.jpg";
		try {
			const randomKey = Math.floor(Math.random() * this.length);
			const randomBoostpackItem = this.getItem(randomKey);
			const mediaItem = randomBoostpackItem.mediaItems[0];
			if (mediaItem) {
				if (mediaItem.thumbSmall && useSmallThumb) {
					result = mediaItem.thumbSmall;
				} else if (mediaItem.thumbMedium) {
					result = mediaItem.thumbMedium;
				} else {
					result = mediaItem.publicFullUrl;
				}
			}
		}
		catch (e) {
			console.log(e);
		}
		return result;
	}
}